@import "../variables";

//Variabili di gestione del container
$mobileContainerGap: 10px;
$tabletContainerGap: 20px;
$desktopContainerGap: 40px;

.ContainerLayout{
  @extend .SizeManager;
  display: flex;
  flex-direction: column;
  align-items: start;

  &.scroll{
    overflow-y: auto;
  }

  &.backgroundBlur{
    backdrop-filter: blur(10px);
  }

  &.fullHeight{
    height: 100%;
  }

  &.grow{
    flex-grow: 1;
  }

  &.center{
    margin: 0 auto;
  }

  @media (min-width: $mobileBkp) and (max-width: $tabletBkp){
    padding-right: 0 !important;
  }

  &.gap{
    @media(min-width: $mobileBkp){
      gap: $mobileContainerGap;
    }

    @media(min-width: $tabletBkp){
      gap: $tabletContainerGap;
    }

    @media(min-width: $desktopBkp){
      gap: $desktopContainerGap;
    }
  }
}
