@import "../variables";

.Section{
  @extend .SizeManager;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &:has(.OverflowTag){
    overflow-y: auto;
  }

  &.scroll{
    overflow-y: scroll;
  }

  &.lightBackground{
    background-color: var(--lightColor);
  }

  &.showBorder{
    border: 1px solid var(--details2Color);
  }

  &.center{
    margin: 0 auto;
  }

  @media(min-width: $mobileBkp){
    gap: $mobileSectionGap;
    &.addPadding{
      padding: $mobileSectionPadding;
    }

  }

  @media(min-width: $tabletBkp){
    gap: $tabletSectionGap;
    &.addPadding{
      padding: $tabletSectionPadding;
    }
  }

  @media(min-width: $desktopBkp){
    gap: $desktopSectionGap;
    &.addPadding{
      padding: $desktopSectionPadding;
    }
  }
}
