@import "../../../Layout/variables";

$mobileCheckboxSize: 30px;
$tabletCheckboxSize: 30px;
$desktopCheckboxSize: 30px;
$lineHeight: 11%;

.Checkbox{
  aspect-ratio: 1 / 1;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  margin: 0 auto;

  @media(min-width: $mobileBkp){
    width: $mobileCheckboxSize !important;
  }

  @media(min-width: $tabletBkp){
    width: $tabletCheckboxSize !important;
  }

  @media(min-width: $desktopBkp) {
    width: $desktopCheckboxSize !important;
  }

  &.disabled{
    >.checkmark{
      background-color: var(--details4Color);
    }
  }

  >.checkmark{
    transition-duration: 0s;

    aspect-ratio: 1 / 1;
    width: 30%;

    clip-path: polygon(35% 0%,65% 0%,65% 35%,100% 35%,100% 60%,65% 60%,65% 100%,35% 100%,35% 60%,0% 60%,0% 35%,35% 35%);
    background-color: var(--details3Color);

    &.checked{
      width: 80%;
      clip-path: polygon(17% 54%, 28% 43%, 38% 54%, 70% 22%, 81% 33%, 38% 75%, 17% 54%);
      background-color: var(--primaryColor);
    }
  }

  >.smallCircle{
    --rotation: deg;

    display: block;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    transform-origin: center;
    transform: rotateZ(calc(var(--rotation) + 180deg));

    &::after{
      content: "";

      aspect-ratio: 1 / 1;
      display: block;
      height: $lineHeight;
      border-radius: 50%;

      position: absolute;
      left: 50%;

      transform: translateX(-50%);
    }

    &.green{
      &::after{
        background-color: var(--primaryColor);
      }
    }

    &.red{
      &::after{
        background-color: var(--details3Color);
      }
    }
  }

  &.disabled{
    >.smallCircle{
      &::after{
        background-color: var(--details4Color);
      }
    }
  }
}
