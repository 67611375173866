@import "../../../Layout/variables";

.HomePageBody{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow-y: auto;
  backdrop-filter: blur(10px);

  .HomePageInternalBody{
    $paddingDesktop: 8px 15%;
    $paddingTablet: 8px 10%;
    $paddingMobile: 5px 15px;

    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    &:has(.OverflowTag){
      height: 100%;
      overflow-y: auto;
    }

    &:has(.GrowTag){
      flex-grow: 1;
    }

    @media(min-width: $mobileBkp){
      padding: $paddingMobile;
    }

    @media(min-width: $tabletBkp){
      padding: $paddingTablet;
    }

    @media(min-width: $desktopBkp){
      padding: $paddingDesktop;
    }
  }
}
