@import "../../Layout/variables";

.ConfigurazioneLine{
  display: flex;
  align-items: center;

  background-color: var(--primaryColor);

  padding: 20px;
  align-self: flex-start;

  gap: 10px;

  @media(min-width: $mobileBkp){
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    >.ResponsiveLabel{
      text-align: center;
    }
  }

  @media(min-width: $tabletBkp){
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 99.7%;

    >.ResponsiveLabel{
      text-align: left;
    }
  }

  @media(min-width: $desktopBkp){
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 99.7%;

    >.ResponsiveLabel{
      text-align: left;
    }
  }

  >.buttonZone{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    >img{
      cursor: pointer;
      width: 40px;

      &:hover{
        transform: scaleX(1.1) scaleY(1.1);
      }
    }
  }
}
