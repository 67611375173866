@import "../../Layout/variables";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap');

@mixin LogoPrefab($logoSize, $mobileLogoMargin, $tabletLogoMargin, $desktopLogoMargin, $ShowBorder: true){
  aspect-ratio: 1.5 / 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-shrink: 0;

  position: relative;
  background-color: var(--primaryColor);

  @media(min-width: $mobileBkp){
    width: $logoSize * 0.6;
    margin: $mobileLogoMargin auto;
  }

  @media(min-width: $tabletBkp){
    width: $logoSize * 0.8;
    margin: $tabletLogoMargin auto;
  }

  @media(min-width: $desktopBkp) {
    width: $logoSize;
    margin: $desktopLogoMargin auto;
  }

  >.title{
    font-family: 'Playfair Display SC';
    color: var(--lightColor);
    line-height: 1;

    @media(min-width: $mobileBkp){
      font-size: $logoSize * 0.33 * 0.6 ;
    }

    @media(min-width: $tabletBkp){
      font-size: $logoSize * 0.33 * 0.8;
    }

    @media(min-width: $desktopBkp) {
      font-size: $logoSize * 0.33;
    }
  }

  >.subtitle{
    font-family: 'Lato';
    color: var(--lightColor);
    font-size: 100%;
    line-height: 0.8;
    font-weight: lighter;

    @media(min-width: $mobileBkp){
      font-size: $logoSize * 0.06 * 0.6;
      letter-spacing: $logoSize * 0.1 * 0.6;
      padding-left: $logoSize * 0.1 * 0.6;
    }

    @media(min-width: $tabletBkp){
      font-size: $logoSize * 0.06 * 0.8;
      letter-spacing: $logoSize * 0.1 * 0.8;
      padding-left: $logoSize * 0.1 * 0.8;
    }

    @media(min-width: $desktopBkp) {
      font-size: $logoSize * 0.06;
      letter-spacing: $logoSize * 0.1;
      padding-left: $logoSize * 0.1;
    }
  }

  @if($ShowBorder){
    &::after{
      content: "";

      display: block;
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;

      transform-origin: center;
      transform: rotateZ(-15deg);

      border: 2px solid var(--primaryColor);
    }
  }
}

.Logo{
  @include LogoPrefab(150px, 0px, 0px, 0px);
}

.LogoBase{
  @include LogoPrefab(160px, 0px, 0px, 0px, false);
}

.LogoTotem{
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  >.LogoImage{
    aspect-ratio: 1 / 1;

    $mobileSize: 50px;
    $tabletSize: 60px;
    $desktopSize: 60px;

    position: relative;
    box-shadow: 0 0 5px 3px black;
    border-radius: 50%;

    @media(min-width: $mobileBkp){
      width: $mobileSize;
    }

    @media(min-width: $tabletBkp){
      width: $tabletSize;
    }

    @media(min-width: $desktopBkp) {
      width: $desktopSize;
    }

    z-index: 20;
  }

  >.LabelZone{
    background-color: var(--primaryColor);
    padding: 2px;
    padding-left: 10px;
    transform: translateX(-8px);
    >.ResponsiveLabel{
      width: auto;
    }
    z-index: 10;
  }
}
