@import "../../../../Layout/variables";

.TiciNavigatorContainer{
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-color: var(--darkColorOpacity);
  backdrop-filter: blur(5px);

  @media(min-width: $mobileBkp) and (max-width: $tabletBkp) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    padding-top: 10%;
    gap: 24px;
  }
}

.ContentContainer{
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  z-index: 10;

  background-color: var(--details7Color);
  border: 1px solid var(--details8Color);
  padding: 16px;

  transition-duration: 0s;

  width: auto;
  height: auto;
  min-width: 30vw;
  max-height: 90vh;

  &:has(.ZonaEditor){
    height: 100%;
    >div{
      height: 100%;
    }
  }

  @media #{$mobileOnly} {
    top: 0 !important;
    left: 0 !important;
    position: relative;
    transform: unset;
  }
}
