@import "../../Layout/variables";

.DownloadLineContainer{
  background-color: var(--primaryColor);
  width: 99%;


  >.PaddingLayout{
    align-items: center;

    >img{
      aspect-ratio: 1 / 1;
      width: 120px;
      margin-right: 20px;
    }

    >.Button{
      margin-left: auto;
      width: 200px;
      height: 50px;
    }
  }
}
