@import "../variables";

.CarouselLayout{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;

  .container{
    $desktopGap: 10px;
    $tabletGap: 8px;
    $mobileGap: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media(min-width: $mobileBkp){
      gap: $mobileGap;
    }

    @media(min-width: $tabletBkp){
      gap: $tabletGap;
    }

    @media(min-width: $desktopBkp){
      gap: $desktopGap;
    }

    .content{
      $desktopGap: 10px;
      $tabletGap: 8px;
      $mobileGap: 5px;

      display: flex;
      flex-direction: row;
      align-items: center;

      overflow-x: scroll;
      height: fit-content;
      width: auto;

      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar{
        display: none;
      }

      @media(min-width: $mobileBkp){
        gap: $mobileGap;
      }

      @media(min-width: $tabletBkp){
        gap: $tabletGap;
      }

      @media(min-width: $desktopBkp){
        gap: $desktopGap;
      }

      >*{
        display: block;
        flex-shrink: 0;
        flex-grow: 0;
        scroll-snap-align: start;
        width: auto;
        height: auto;
      }
    }

    .spinButton{
      $desktopSize: 40px;
      $tabletSize: 30px;
      $mobileSize: 20px;

      aspect-ratio: 1 / 1;

      display: none;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      background-color: var(--details1Color);

      cursor: pointer;
      border-radius: 4px;

      >.ResponsiveLabel{
        transform: scaleY(2) rotateZ(-90deg);
        color: var(--lightColor);
      }

      @media(min-width: $mobileBkp){
        display: none;
        width: $mobileSize;
      }

      @media(min-width: $tabletBkp){
        &.scroll{
          display: flex;
        }
        width: $tabletSize;
      }

      @media(min-width: $desktopBkp){
        &.scroll{
          display: flex;
        }
        width: $desktopSize;
      }
    }
  }
}
