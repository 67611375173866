@import "../../../../Layout/variables";

.ChatOverlaySender {
  $desktopPadding: 5px 10px;
  $tabletPadding: 3px 8px;
  $mobilePadding: 2px 5px;

  display: flex;
  align-items: center;

  width: 100%;
  border: 2px var(--primaryColor) solid;

  @media(min-width: $mobileBkp){
    padding: $mobilePadding;
    gap: 4px;
    flex-direction: column;
  }

  @media(min-width: $tabletBkp){
    padding: $tabletPadding;
    gap: 0;
    flex-direction: row;
  }

  @media(min-width: $desktopBkp){
    padding: $desktopPadding;
    gap: 0;
    flex-direction: row;
  }

  >.textField{
    $moltiplicatoreFont: 1.4;

    color: var(--primaryColor);
    flex-grow: 1;
    max-width: 100%;
    width: 100%;

    @media(min-width: $mobileBkp){
      font-size: $mobileInputFontSize * $moltiplicatoreFont;
      border-bottom: 2px solid var(--primaryColor);
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletInputFontSize * $moltiplicatoreFont;
      border-bottom: none;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopInputFontSize * $moltiplicatoreFont;
      border-bottom: none;
    }
  }

  >.buttonZone{
    $desktopGap: 5px;
    $tabletGap: 5px;
    $mobileGap: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;

    @media(min-width: $mobileBkp){
      gap: $mobileGap;
    }

    @media(min-width: $tabletBkp){
      gap: $tabletGap;
    }

    @media(min-width: $desktopBkp){
      gap: $desktopGap;
    }

    >.button{
      $desktopSize: 40px;
      $tabletSize: 35px;
      $mobileSize: 30px;
      $desktopPadding: 5px;
      $tabletPadding: 3px;
      $mobilePadding: 2px;

      border-radius: 5px;

      cursor: pointer;
      background-color: var(--primaryColor);

      @media(min-width: $mobileBkp){
        width: $mobileSize;
        padding: $mobilePadding;
      }

      @media(min-width: $tabletBkp){
        width: $tabletSize;
        padding: $tabletPadding;
      }

      @media(min-width: $desktopBkp){
        width: $desktopSize;
        padding: $desktopPadding;
      }
    }
  }
}
