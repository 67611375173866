@import "../../../Layout/variables";

.ChatOverlay{
    --chatX: 100px;
    --chatY: 100px;

    position: fixed;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: auto;
    height: auto;

    transition-duration: 0s;

    background-color: white;
    border: 5px solid var(--primaryColor);

    z-index: 10;

    top: var(--chatY);
    left: var(--chatX);

    @extend .Shadow;

    @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
        width: 100%;
        top: unset;
        left: unset;
        position: relative;
    }

    &.drag{
        cursor: grabbing;
    }

    >.ChatContainer{
        $desktopPadding: 20px;
        $tabletPadding: 15px;
        $mobilePadding: 10px;

        width: 100%;
        display: flex;
        flex-direction: column;

        overflow: auto;

        min-width: 600px;
        max-width: 800px;
        min-height: 300px;
        max-height: 500px;

        @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
            padding: $mobilePadding;
            gap: $mobilePadding;
            resize: none;
            min-width: unset;
            max-width: 100%;
            max-height: 500px;
            height: 100% !important;
            width: 100% !important;
        }

        @media(min-width: $tabletBkp){
            padding: $tabletPadding;
            gap: $tabletPadding;
            resize: both;
        }

        @media(min-width: $desktopBkp){
            padding: $desktopPadding;
            gap: $desktopPadding;
            resize: both;
        }

        &.closed{
            height: 0 !important;
            width: auto !important;
            min-height: unset;
            max-height: unset;
            min-width: auto;
            max-width: 300px;
            padding: 0;

            @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
                width: 100% !important;
            }
        }

        >.MessagesContainer{
            $desktopGap: 15px;
            $tabletGap: 10px;
            $mobileGap: 8px;

            display: flex;
            flex-direction: column;
            width: 100%;
            flex-grow: 1;

            overflow: auto;

            @media(min-width: $mobileBkp){
                gap: $mobileGap;
            }

            @media(min-width: $tabletBkp){
                gap: $tabletGap;
            }

            @media(min-width: $desktopBkp){
                gap: $desktopGap;
            }

            &::-webkit-scrollbar-thumb{
                display: none;
            }
        }
    }
}
