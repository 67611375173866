.PreviewContainer{
  display: block;
  width: 95%;
  overflow: hidden;
  margin: 0 auto;
  flex-direction: column;
  border: 1px dashed var(--primaryColor);
  background-color: var(--lightColor);

  >.PreviewContent{
    display: block;
    column-fill: auto;
    gap: var(--internalSpace);
    overflow: hidden;
    width: 100%;
    height: 100%;

    >*{
      break-inside: avoid-column;
    }

    >.PreviewCol{
      display: flex;
      flex-direction: column;
      gap: var(--internalSpace);
    }

    .ResponsiveLabel{
      font-size: var(--normalSize);
      &.large{
        font-size: var(--largeSize);
      }
    }

    .OrdineLineTopSeparator{
      &:not(:first-child){
        margin-top: var(--internalSpace);
      }
      padding: var(--internalSpace);
      gap: var(--internalSpace);
      border-width: var(--borderSize);
    }

    .Recap{
      padding: var(--internalSpace);
      border-width: var(--borderSize);
    }
  }
}
