@import "../../Layout/variables";

.OrdineContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  gap: inherit;

  @media(min-width: $mobileBkp){
    gap: $mobileSectionGap;
  }

  @media(min-width: $tabletBkp){
    gap: $tabletSectionGap;
  }

  @media(min-width: $desktopBkp){
    gap: $desktopSectionGap;
  }
}
