@import "../variables";

.BlurBackground{
  display: block;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
}

.DesktopModeConfiguratoreLayout{
  display: grid;
  width: 100%;
  height: 100%;

  @media(min-width: $mobileBkp){
    grid-template-columns: 0fr 1fr 0fr;
  }

  @media(min-width: $tabletBkp){
    grid-template-columns: 1fr 10fr 1fr;
  }

  @media(min-width: $desktopBkp){
    grid-template-columns: 1fr 4fr 1fr;
  }

  >.LeftSide, >.RightSide{
    display: block;

    @media(min-width: $mobileBkp){
      max-width: 0;
      opacity: 0;
    }

    @media(min-width: $tabletBkp){
      max-width: 0;
      opacity: 0;
    }

    @media(min-width: $desktopBkp){
      opacity: 1;
      max-width: unset;
    }
  }

  >.LeftSide{
    position: relative;

    >.VerticalLabel{
      white-space: nowrap;
      position: absolute;
      text-transform: uppercase;
      transform-origin: top left;
      transform: rotateZ(-90deg) translateY(-50%);
      font-weight: bold;
      color: var(--details4Color);

      opacity: 0.6;

      left: 50%;
      bottom: 0;
      font-size: 120px;
    }
  }
}
