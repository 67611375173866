@import "../../../Layout/variables.scss";

.CategorySwitcherContainer{
    display: flex;
    flex-direction: column;
    width: 100%;

    background-color: var(--details2Color);

    >.CategorySwitcher{

        $desktopPadding: 5px;
        $tabletPadding: 8px;
        $mobilePadding: 10px;

        background-color: var(--primaryColor);
        width: 100%;

        @media(min-width: $mobileBkp){
            display: grid;
            padding: $mobilePadding;
            grid-template-columns: 1fr 1fr 1fr;

            &.fr2Mobile{
                grid-template-columns: 1fr 1fr;
            }
        }

        @media(min-width: $tabletBkp){
            display: flex;
            padding: $tabletPadding;
            flex-direction: row;
            justify-content: space-evenly;
        }

        @media(min-width: $desktopBkp){
            display: flex;
            padding: $desktopPadding;
            flex-direction: row;
            justify-content: space-evenly;
        }
    }
}
