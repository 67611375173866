*{
    --primaryColor: #43768f;
    --details1Color: #508dab;
    --details2Color: #508dab;
    --details3Color: #e05353;
    --details4Color: #a7bcc7;
    --details5Color: #e39191;
    --details6Color: #ffae3d;
    --details7Color: #d5dce2;
    --details8Color: #adbbc8;
    --details9Color: #577287;
    --textColor: #434343;
    --lightColor: white;
    --darkColor: #323232;
    --darkColorOpacity: rgba(50, 50, 50, 0.2);
    --darkColorOpacityDark: rgba(50, 50, 50, 0.8);
}

/*Vecchi colori TICI*/

/**{
    --primaryColor: #8DA598FF;
    --details1Color: #63844b;
    --details2Color: #637468;
    --details3Color: #e05353;
    --details4Color: #ddd;
    --textColor: #555555;
    --lightColor: white;
    --darkColor: #323232;
}*/

