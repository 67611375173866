@import "../../Layout/variables";

.CardFotografoContainer{
    $mobilePadding: 15px;
    $tabletPadding: 20px 15px;
    $desktopPadding: 20px 15px;

    display: flex;
    flex-direction: column;
    background-color: var(--primaryColor);
    width: 100%;
    gap: 20px;

    @media(min-width: $mobileBkp){
        padding: $mobilePadding;
    }

    @media(min-width: $tabletBkp){
        padding: $tabletPadding;
    }

    @media(min-width: $desktopBkp){
        padding: $desktopPadding;
    }

    >.CardFotografoHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        gap: 10px;

        &.modalitaRidotta{
            align-items: center;
            @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
                flex-direction: row;
            }
        }

        @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
            flex-direction: column;
        }

        >.ProfileImage{
            $mobileWidth: 80px;
            $tabletWidth: 100px;
            $desktopWidth: 100px;

            aspect-ratio: 1 / 1;
            display: block;

            flex-shrink: 0;
            background-color: var(--lightColor);
            border-radius: 10px;

            background-position: center;
            background-size: cover;

            @media(min-width: $mobileBkp){
                width: $mobileWidth;
            }

            @media(min-width: $tabletBkp){
                width: $tabletWidth;
            }

            @media(min-width: $desktopBkp){
                width: $desktopWidth;
            }
        }

        .HeaderButton{
            $mobileWidth: 35px;
            $tabletWidth: 35px;
            $desktopWidth: 40px;

            cursor: pointer;
            margin: 0 auto;

            &:hover{
                transform: scaleX(1.1) scaleY(1.1);
            }

            @media(min-width: $mobileBkp){
                width: $mobileWidth;
            }

            @media(min-width: $tabletBkp){
                width: $tabletWidth;
            }

            @media(min-width: $desktopBkp){
                width: $desktopWidth;
            }
        }
    }

    >.CardFotografoBody{
        display: flex;
        flex-direction: column;
        background-color: white;
        gap: 10px;

        @media(min-width: $mobileBkp){
            padding: $mobilePadding;
        }

        @media(min-width: $tabletBkp){
            padding: $tabletPadding;
        }

        @media(min-width: $desktopBkp){
            padding: $desktopPadding;
        }

        .DefaultInput{
            color: var(--details1Color);
            @media(min-width: $mobileBkp){
                font-size: $mobileLabel;
                padding: 2px;
            }

            @media(min-width: $tabletBkp){
                font-size: $tabletLabel;
                padding: 4px;
            }

            @media(min-width: $desktopBkp){
                font-size: $desktopLabel;
                padding: 6px;
            }
        }

        .ItemElement{
            color: var(--details1Color);
        }
    }
}
