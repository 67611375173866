@import "../../../Layout/variables";

.ElementSelect{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;

  >.inputWrapper{
    display: block;
    position: relative;
    width: 100%;
    height: auto;

    cursor: pointer;

    &:not(.autocompleteMode){
      input{
        user-select: none;
        caret-color: transparent;
        cursor: pointer;
      }
    }

    >.arrow{
      position: absolute;
      top: 50%;
      height: 50%;
      transition-duration: 0.2s;
      transform: translateY(-50%);

      &.open{
        transform: translateY(-50%) rotateZ(180deg);
      }

      @media(min-width: $mobileBkp){
        right: $mobileInputPadding * 2;
      }

      @media(min-width: $tabletBkp){
        right: $tabletInputPadding * 2;
      }

      @media(min-width: $desktopBkp){
        right: $desktopInputPadding * 2;
      }
    }
  }

  >.itemContainer{
    display: none;

    position: absolute;
    background-color: var(--lightColor);
    border: 1px solid var(--details2Color);

    width: 100%;
    z-index: 1000;

    overflow-y: auto;

    scroll-snap-type: y mandatory;

    &.blockMode{
      position: relative;
    }

    &.open{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2px;
    }

    .ItemElement{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      scroll-snap-align: start;

      &:not(.noSelect){
        cursor: pointer;
        &:hover{
          background-color: var(--primaryColor);
          color: var(--lightColor);
        }
      }

      color: var(--textColor);
      @media(min-width: $mobileBkp){
        font-size: $mobileInputFontSize;
        padding: $mobileInputPadding;
      }

      @media(min-width: $tabletBkp){
        font-size: $tabletInputFontSize;
        padding: $tabletInputPadding;
      }

      @media(min-width: $desktopBkp){
        font-size: $desktopInputFontSize;
        padding: $desktopInputPadding;
      }

      >.image{
        aspect-ratio: 1 / 1;
        background-position: center;
        background-size: cover;

        @media(min-width: $mobileBkp){
          width: 30px;
        }

        @media(min-width: $tabletBkp){
          width: 50px;
        }

        @media(min-width: $desktopBkp){
          width: 70px;
        }
      }

      &:hover{
        >.image{
          transition-delay: 1.2s;

          @media(min-width: $mobileBkp){
            width: 140px;
          }

          @media(min-width: $tabletBkp){
            width: 160px;
          }

          @media(min-width: $desktopBkp){
            width: 200px;
          }
        }
      }
    }
  }
}
