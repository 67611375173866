@import "../../Layout/variables";

.StopLightContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
    gap: 4px;
    border: 1px solid var(--lightColor);
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--primaryColor);

    >.light{
        aspect-ratio: 1 / 1;
        display: block;
        width: 18px;
        background-color: var(--details1Color);
        border-radius: 50%;

        &.r{
            background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(0,0,0,0.3) 100%);
            background-color: red;
        }

        &.y{
            background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(0,0,0,0.3) 100%);
            background-color: yellow;
        }

        &.g{
            background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(0,0,0,0.3) 100%);
            background-color: green;
        }
    }
}

