@import "../variables";

.AddressLayout{
  display: grid;
  width: 100%;
  grid-template-columns: 10fr 8fr 5fr 10fr 6fr;
  gap: $mobileSectionGap;

  @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
    display: flex;
    flex-direction: column;
  }
}
