@import "../../Layout/variables";

$desktopSize: 50px;
$tabletSize: 40px;
$mobileSize: 30px;

.Loader{
  aspect-ratio: 1 / 1;
  position: relative;
  margin: 0 auto;
  flex-shrink: 0;

  >.ball{
    --startDelay: 0s;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    &:nth-of-type(2){
      --startDelay: 0.2s;
      rotate: 40deg;
    }

    &:nth-of-type(3){
      --startDelay: 0.4s;
      rotate: 80deg;
    }

    &:nth-of-type(4){
      --startDelay: 0.6s;
      rotate: 120deg;
    }

    &:nth-of-type(5){
      --startDelay: 0.8s;
      rotate: 160deg;
    }

    &:nth-of-type(6){
      --startDelay: 1s;
      rotate: 200deg;
    }

    &:nth-of-type(7){
      --startDelay: 1.2s;
      rotate: 240deg;
    }

    &:nth-of-type(8){
      --startDelay: 1.4s;
      rotate: 280deg;
    }

    &:nth-of-type(9){
      --startDelay: 1.6s;
      rotate: 320deg;
    }

    &::before{
      content: "";
      aspect-ratio: 1 / 1;
      position: absolute;
      top: 0;
      left: 50%;

      width: 15%;
      border-radius: 50%;

      background-color: var(--primaryColor);

      animation-duration: 1.6s;
      animation-iteration-count: infinite;
      animation-delay: var(--startDelay);
      animation-name: ballAnimation;

      @keyframes ballAnimation {
        0%{
          transform: translateX(-50%) scale(1);
        }

        50%{
          transform: translateX(-50%) scale(0.5);
        }

        100%{
          transform: translateX(-50%) scale(1);
        }
      }
    }
  }

  @media(min-width: $mobileBkp){
    width: $mobileSize;
  }

  @media(min-width: $tabletBkp){
    width: $tabletSize;
  }

  @media(min-width: $desktopBkp){
    width: $desktopSize;
  }
}
