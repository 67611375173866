@import "../../Layout/variables";

.TextArea{
  resize: none;

  width: 100%;
  border: 1px solid var(--details2Color);
  color: var(--textColor);

  &.noEdit{
    user-select: none;
    caret-color: transparent;
    cursor: default;
  }

  @media(min-width: $mobileBkp){
    font-size: $mobileInputFontSize;
    padding: $mobileInputPadding;
  }

  @media(min-width: $tabletBkp){
    font-size: $tabletInputFontSize;
    padding: $tabletInputPadding;
  }

  @media(min-width: $desktopBkp){
    font-size: $desktopInputFontSize;
    padding: $desktopInputPadding;
  }
}
