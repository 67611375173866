@import "../variables";

.Fr2frLayout{
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  &:has(.OverflowTag){
    overflow-y: auto;
  }

  @media(min-width: $mobileBkp){
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $mobileResponsiveRowGap * 10;
  }

  @media(min-width: $tabletBkp){
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $mobileResponsiveRowGap * 10;
  }

  @media(min-width: $desktopBkp){
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: $desktopResponsiveColGap;
  }
}
