@import "../../Layout/variables";

.ResponsiveLabel{
  display: block;
  max-width: 100%;
  width: 100%;
  color: var(--textColor);
  overflow: hidden;
  text-overflow: ellipsis;

  &.noWrap{
    white-space: nowrap;
  }

  &.zeroShrink{
    flex-shrink: 0;
  }

  &.italic{
    text-transform: unset;
    font-style: italic;
  }

  &.bold{
    font-weight: bold;
  }

  &.uppercase{
    text-transform: uppercase;
  }

  &.left{
    text-align: left;
    margin-right: auto;
  }

  &.center{
    text-align: center;
    margin: auto;
  }

  &.right{
    text-align: right;
    margin-left: auto;
  }

  &.xxlarge{
    @media(min-width: $mobileBkp){
      font-size: $mobileLabel * 3.5;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletLabel * 3.5;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopLabel * 3.5;
    }
  }

  &.xlarge{
    @media(min-width: $mobileBkp){
      font-size: $mobileLabel * 2.5;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletLabel * 2.5;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopLabel * 2.5;
    }
  }

  &.large{
    @media(min-width: $mobileBkp){
      font-size: $mobileLabel * 1.5;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletLabel * 1.5;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopLabel * 1.5;
    }
  }

  &.xmedium{
    @media(min-width: $mobileBkp){
      font-size: $mobileLabel * 1.3;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletLabel * 1.3;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopLabel * 1.3;
    }
  }

  &.medium{
    @media(min-width: $mobileBkp){
      font-size: $mobileLabel;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletLabel;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopLabel;
    }
  }

  &.xsmall{
    @media(min-width: $mobileBkp){
      font-size: $mobileLabel * 0.8;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletLabel * 0.8;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopLabel * 0.8;
    }
  }

  &.small{
    @media(min-width: $mobileBkp){
      font-size: $mobileLabel * 0.6;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletLabel * 0.6;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopLabel * 0.6;
    }
  }

  &.very-small{
    @media(min-width: $mobileBkp){
      font-size: $mobileLabel * 0.4;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletLabel * 0.4;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopLabel * 0.4;
    }
  }

  //Tipologie di label
  &.separatorLabel{
    position: relative;
    color: var(--details2Color);
    &::after{
      content: "";
      position: absolute;

      bottom: 0;
      left: 0;

      width: 100%;
      border-bottom: 1px solid var(--details2Color);
    }
  }

  &.recapLabel{
    width: auto;
    color: var(--details2Color);
  }

  &.errorLabel{
    width: auto;
    color: var(--details3Color);
  }

  &.warningLabel{
    width: auto;
    color: var(--details6Color);
  }

  &.whiteLabel{
    color: var(--lightColor);
  }

  &.leftOnMobile{
    @media (min-width: $mobileBkp) and (max-width: $tabletBkp){
      text-align: left;
    }
  }

  &._404{
    margin-top: auto;
    margin-bottom: auto;

    font-size: 5vh;
  }

  &.footer{
    color: var(--lightColor);
    text-transform: unset;
    padding: 2px;
    background-color: var(--details1Color);
    opacity: 0.4;
    margin: 5px 0;
  }

  &.footerHp{
    $paddingDesktop: 5px 15%;
    $paddingTablet: 5px 10%;
    $paddingMobile: 5px 20px;

    color: var(--lightColor);
    text-transform: unset;
    padding: 2px;
    background-color: var(--primaryColor);
    margin: 0;
    margin-top: auto;
    flex-shrink: 0;
    z-index: 200;

    @media(min-width: $mobileBkp){
      padding: $paddingMobile;
    }

    @media(min-width: $tabletBkp){
      padding: $paddingTablet;
    }

    @media(min-width: $desktopBkp){
      padding: $paddingDesktop;
    }
  }

  &.cardAgenteInfo{
    color: var(--primaryColor);
  }
}
