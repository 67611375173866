@import "../../Layout/variables";
.QuadImage{
  $desktopSize: 50%;
  $tabletSize: 50%;
  $mobileSize: 100%;

  @media(min-width: $mobileBkp){
    width: $mobileSize;
  }

  @media(min-width: $tabletBkp){
    width: $tabletSize;
  }

  @media(min-width: $desktopBkp){
    width: $desktopSize;
  }
}

.ScrollZone{
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 250px;
  overflow-y: auto;

  @media(min-width: $mobileBkp){
    gap: $mobileResponsiveRowGap;
  }

  @media(min-width: $tabletBkp){
    gap: $tabletResponsiveRowGap;
  }

  @media(min-width: $desktopBkp){
    gap: $desktopResponsiveRowGap;
  }

  .ScrollZoneElement{
    $mobilePadding: 2px;
    $tabletPadding: 5px;
    $desktopPadding: 5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 99%;

    background-color: var(--lightColor);

    border: 1px solid var(--details2Color);
    gap: 10px;

    @media(min-width: $mobileBkp){
      padding: $mobilePadding;
    }

    @media(min-width: $tabletBkp){
      padding: $tabletPadding;
    }

    @media(min-width: $desktopBkp){
      padding: $desktopPadding;
    }

    >.image{
      aspect-ratio: 1 / 1;
      width: 32px;
      object-fit: cover;
    }

    >.deleteButton{
      $mobileHeight: 20px;
      $tabletHeight: 25px;
      $desktopHeight: 30px;
      $mobilePadding: 5px;
      $tabletPadding: 8px;
      $desktopPadding: 10px;

      background-color: var(--details3Color);

      aspect-ratio: 1 / 1;
      display: block;

      cursor: pointer;

      @media(min-width: $mobileBkp){
        height: $mobileHeight;
        padding: $mobilePadding;
      }

      @media(min-width: $tabletBkp){
        height: $tabletHeight;
        padding: $tabletPadding;
      }

      @media(min-width: $desktopBkp){
        height: $desktopHeight;
        padding: $desktopPadding;
      }
    }
  }
}

.ImageVisualizer{
  $imageAspectRatio: 2 / 1;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  height: auto;
  overflow-y: hidden;

  >.image{
    aspect-ratio: $imageAspectRatio;
    width: 100%;
    object-fit: contain;
  }

  >.deleteButton{
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;

    width: 100%;
    height: 10%;

    top: 100%;

    background-color: var(--details3Color);
    opacity: 0.8;

    cursor: pointer;

    &::after{
      content: "";
      aspect-ratio: 1 / 1;

      display: block;
      height: 80%;

      background-image: url("../../Media/Images/Icons/xIcon.png");
      background-size: cover;
    }
  }

  &:hover{
    >.deleteButton{
      transform: translateY(-100%);
    }
  }
}

.RecapElement{
  $mobilePadding: 2px;
  $tabletPadding: 4px;
  $desktopPadding: 6px;
  $mobileGap: 10px;
  $tabletGap: 5px;
  $desktopGap: 4px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  position: relative;

  border: 1px solid var(--details2Color);
  background-color: var(--lightColor);

  @media(min-width: $mobileBkp){
    padding: $mobilePadding;
    gap: $mobileGap;
  }

  @media(min-width: $tabletBkp){
    padding: $tabletPadding;
    gap: $tabletGap;
  }

  @media(min-width: $desktopBkp){
    padding: $desktopPadding;
    gap: $desktopGap;
  }

  >.image{
    $mobileSize: 20px;
    $tabletSize: 25px;
    $desktopSize: 30px;

    @media(min-width: $mobileBkp){
      height: $mobileSize;
    }

    @media(min-width: $tabletBkp){
      height: $tabletSize;
    }

    @media(min-width: $desktopBkp){
      height: $desktopSize;
    }
  }

  .hidden-riepilogo-utente{
    display: none;
  }
}
