@import "../../../../Layout/variables";

.ZonaEditor{
  display: flex;
  flex-direction: column;
  width: auto;
  flex-grow: 1;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: white;

  >.EditorImmagine{
    height: 100%;
    background-color: rgb(241, 241, 241);
    position: relative;

    outline: 2px dashed var(--details6Color);
    outline-offset: -2px;
    overflow: hidden;

    >.ImmagineEditor{
      position: absolute;
      transform-origin: center;
    }

    >.InidicazioniPosizione{
      display: flex;
      flex-direction: column;
      gap: 2px;
      position: absolute;
      padding: 2px;
      background-color: rgba(0, 0, 0, 0.3);
    }

    >.LineaIndicazioniPosizione{
      display: block;
      position: absolute;
      border-top: 2px dashed var(--details6Color);
      border-left: 2px dashed var(--details6Color);
    }
  }

  >.ZonaPulsanti{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2px;

    position: absolute;

    &.column{
      flex-direction: column;
    }

    >.PulsanteZona{
      aspect-ratio: 1 / 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 40px;

      background-color: var(--details4Color);

      &:hover{
        background-color: var(--details1Color);
      }

      >img{
        width: 80%;
      }
    }
  }
}
