.InputFieldContainer{
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;

  >.ButtonElimina{
    aspect-ratio: 1 / 1;
    display: block;
    flex-shrink: 0;
    background-color: var(--details5Color);
    width: 40px;
    cursor: pointer;

    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
  }
}
