@import "../variables.scss";

$desktopPadding: 8px;
$tabletPadding: 8px;
$mobilePadding: 8px;


.FormLayout{
  width: 90%;
  position: relative;
  margin: 3% auto;
  flex-grow: 1;

  &.versione-0{
    &::before{
      content: "";

      display: block;
      width: 100%;
      height: 100%;

      background-color: var(--primaryColor);

      position: absolute;
      top: 0;
      left: 0;
      transform-origin: center;
      transform: rotateZ(-2deg);

      z-index: -20;
    }

    &::after{
      content: "";

      display: block;
      width: 100%;
      height: 100%;

      background-color: white;

      position: absolute;
      top: 0;
      left: 0;

      outline: solid 2px var(--primaryColor);

      z-index: -10;
    }
  }

  &.versione-1{
    width: 100%;
    margin: 0;
  }

  @media(min-width: $mobileBkp){
    padding: $mobilePadding;
  }

  @media(min-width: $tabletBkp){
    padding: $tabletPadding;
  }

  @media(min-width: $desktopBkp){
    padding: $desktopPadding;
  }
}
