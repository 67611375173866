@import "../../../../Layout/variables";

.MenuRadialeContainer{
  --posizioneXContainer: 50%;
  --posizioneYContainer: 50%;

  @extend .Shadow;
  aspect-ratio: 1 / 1;

  position: fixed;
  top: var(--posizioneYContainer);
  left: var(--posizioneXContainer);
  transform: translateX(-50%) translateY(-50%);

  flex-shrink: 0;

  width: 300px;
  background-color: var(--details8Color);
  border-radius: 50%;

  transition-duration: 0s;

  opacity: 0.7;
  &:hover{
    opacity: 0.9;
  }

  @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
    top: 0;
    left: 0;
    position: relative;
    width: 60%;
    max-width: 350px;
    transform: translateX(0) translateY(0);
  }

  >.TitoloMenu{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) rotateZ(-47deg);
  }

  >.Segmento{
    --startAngle: 0deg;
    --segmentAngle: 0deg;
    --borderColor: var(--details8Color);

    position: absolute;
    width: 100%;
    height: 100%;

    flex-shrink: 0;
    transform-origin: center;
    transform: rotateZ(var(--startAngle));
    background-color: var(--details7Color);

    opacity: 0.5;

    border: 2px solid var(--borderColor);
    border-radius: 50%;

    .ResponsiveLabel{
      color: var(--details8Color) !important;
    }

    &:hover, &.selected{
      --borderColor: var(--details9Color);
      cursor: pointer;
      opacity: 1;

      .ResponsiveLabel{
        color: var(--details9Color) !important;
      }
    }

    &::before, &::after{
      content: "";
      background-color: var(--borderColor);
      width: 100%;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 0;
      transform-origin: center;
      transform: translateY(calc(-100% + 1.5px));
    }

    &::after{
      transform: rotateZ(calc(var(--segmentAngle) - 0.5deg));
    }

    >.PivotSegmento{
      position: absolute;
      width: 4px;
      height: 4px;
      top: calc(50% + (sin(calc(var(--segmentAngle) / 2)) * 42%));
      left: calc(50% + (cos(calc(var(--segmentAngle) / 2)) * 42%));
      transform: translateX(-50%) translateY(-50%);

      >.ContentSegmento{
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-50%) translateY(-50%) rotateZ(calc((var(--segmentAngle) / 2) + 90deg));
      }
    }
  }
}
