@import "../../Layout/variables";

.CardAgenteContainer{
  $mobilePadding: 5px;
  $tabletPadding: 8px;
  $desktopPadding: 8px;

  width: 99.7%;
  align-self: flex-start;
  background-color: var(--primaryColor);
  border-right: 2px var(--details1Color);
  gap: 10px;

  @media(min-width: $mobileBkp){
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: $mobilePadding;
  }

  @media(min-width: $tabletBkp){
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    padding: $tabletPadding;
  }

  @media(min-width: $desktopBkp){
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    padding: $desktopPadding;
  }

  .CardAgenteProfilo{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 100%;

    >.CardAgenteImmagineProfilo{
      $mobileWidth: 80px;
      $tabletWidth: 100px;
      $desktopWidth: 100px;

      aspect-ratio: 1 / 1;
      object-fit: cover;
      object-position: center;
      align-self: flex-start;

      @media(min-width: $mobileBkp){
        width: $mobileWidth;
      }

      @media(min-width: $tabletBkp){
        width: $tabletWidth;
      }

      @media(min-width: $desktopBkp){
        width: $desktopWidth;
      }
    }

    >.CardAgentePulsanteProfilo{
      aspect-ratio: 1 / 1;
      width: 50px;
      flex-shrink: 0;
      background-color: var(--primaryColor);
      background-size: 80%;
      border-radius: 10px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;

      @media(min-width: $mobileBkp){
        padding: $mobilePadding;
      }

      @media(min-width: $tabletBkp){
        padding: $tabletPadding;
      }

      @media(min-width: $desktopBkp){
        padding: $desktopPadding;
      }
    }
  }

  .CardAgenteInformazioniFotografi{
    background-color: var(--lightColor);
    height: 100%;

    .RigaInserimento{
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 2fr 1fr;
      gap: 2px;

      @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .Button{
          height: 32px;
        }
      }
    }

    .ContainerFotografi{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      max-height: 500px;
      overflow-y: auto;
      gap: 5px;

      >.CardAgenteRigaFotografo{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: var(--primaryColor);
        overflow: hidden;
        width: 99.7%;

        flex-shrink: 0;

        @media(min-width: $mobileBkp){
          padding: $mobilePadding $mobilePadding * 1.5;
        }

        @media(min-width: $tabletBkp){
          padding: $tabletPadding $tabletPadding * 1.5;
        }

        @media(min-width: $desktopBkp){
          padding: $desktopPadding $desktopPadding * 1.5;
        }

        >.RigaFotografoImage{
          $mobileWidth: 30px;
          $tabletWidth: 50px;
          $desktopWidth: 50px;

          flex-shrink: 0;
          aspect-ratio: 1 / 1;
          background-size: cover;
          background-position: center;
          background-color: var(--lightColor);

          @media(min-width: $mobileBkp){
            width: $mobileWidth;
          }

          @media(min-width: $tabletBkp){
            width: $tabletWidth;
          }

          @media(min-width: $desktopBkp){
            width: $desktopWidth;
          }
        }

        >.RigaFotografoDeleteButton{
          aspect-ratio: 1 / 1;
          width: 25px;
          flex-shrink: 0;
          cursor: pointer;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }
}
