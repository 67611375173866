@import "../../Layout/variables";

.BackgroundOrdineManager{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;

  &:has(.OverflowTag){
    flex-grow: 1;
    overflow-y: auto;
  }

  &:has(.GrowTag){
    flex-grow: 1;
  }

  >.BackgroundOrdineContainer{
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: 2px;
    right: 5px;
    top: 5px;
    width: auto;
    height: auto;
    max-height: 200px;
    opacity: 0.5;

    &.espansa{
      width: 300px;
    }

    &:hover{
      opacity: 1;
    }

    background-color: var(--primaryColor);
    z-index: 10;
    padding: 5px;

    @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
      position: relative;
      right: 0;
      top: 0;
      max-height: 20%;
      width: calc(100% - 80px) !important;
      padding: 0 ;
      margin: 5px 40px;

      &:not(.espansa){
        padding: 10px;
        align-items: center;
      }
    }

    >.ScrollContainer{
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 2px;
      height: 100%;
      width: 100%;
      overflow-y: auto;

      >.OrdineProgressLine{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 99.7%;
        height: auto;
        background-color: var(--details1Color);
        padding: 5px;
        gap: 5px;

        >.DataContainer{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;

          >.TitoloSottotitoloContainer{
            display: flex;
            flex-direction: column;
          }

          >.ResponsiveLabel{
            width: auto;
            margin: unset;
          }
        }

        >.Loader{
          width: unset !important;
          height: 20px;
          >.ball{
            &::before{
              background-color: var(--lightColor);
            }
          }
        }
      }
    }

    >.ListButton{
      aspect-ratio: 1 / 1;
      display: block;
      width: 60px;
      background-size: cover;
      cursor: pointer;
    }
  }
}
