@import "../variables";

.PaddingLayout{
  display: flex;
  justify-content: flex-start;
  align-items: start;

  width: 100%;
  height: 100%;

  --paddingMobile: 0;
  --paddingTablet: 0;
  --paddingDesktop: 0;

  @media(min-width: $mobileBkp){
    padding: var(--paddingMobile);
  }

  @media(min-width: $tabletBkp){
    padding: var(--paddingTablet);
  }

  @media(min-width: $desktopBkp){
    padding: var(--paddingDesktop);
  }
}
