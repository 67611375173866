@import "../../../Layout/variables";

*{
    &::-webkit-scrollbar {
        width: 3px;
        height: 2px;

        @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
            display: none;
        }
    }

    &::-webkit-scrollbar-track {
        background: var(--lightColor);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--primaryColor);
    }

    &::-webkit-scrollbar-thumb:hover{
        background: var(--details2Color);
    }
}
