@import "../../../../Layout/variables";

.EditorSezioneSuperficieContainer{
  width: 100%;

  border: 1px solid #2fff39;
  position: relative;

  @include mobile-only{
    width: 100%;
  }

  >.ShapeSezioneSuperficie{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: #2fff39;
  }

  >.PuntoPoligonoSezioneSuperficie{
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;

    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    width: 20px;
    background-color: yellow;
    border: 1px solid #2fff39;
    border-radius: 50%;

    cursor: grab;

    &.grabbing{
      cursor: grabbing;
    }
  }
}
