@import "../../Layout/variables";

.MaterialeLine{
  display: flex;
  align-items: center;
  gap: 4px;

  width: 100%;

  >.materialImage{
    $mobileHeight: 60px;
    $tabletHeight: 80px;
    $desktopHeight: 100px;

    aspect-ratio: 1 / 1;
    display: block;
    object-position: center;
    object-fit: cover;
    flex-shrink: 0;

    @media(min-width: $mobileBkp){
      height: $mobileHeight;
    }

    @media(min-width: $tabletBkp){
      height: $tabletHeight;
    }

    @media(min-width: $desktopBkp){
      height: $desktopHeight;
    }
  }

  >.deleteButton{
    $mobileHeight: 35px;
    $tabletHeight: 45px;
    $desktopHeight: 45px;
    $mobilePadding: 5px;
    $tabletPadding: 8px;
    $desktopPadding: 10px;

    background-color: var(--details3Color);

    aspect-ratio: 1 / 1;
    display: block;
    border-radius: 5px;

    flex-shrink: 0;
    margin-left: auto;
    cursor: pointer;

    @media(min-width: $mobileBkp){
      height: $mobileHeight;
      padding: $mobilePadding;
    }

    @media(min-width: $tabletBkp){
      height: $tabletHeight;
      padding: $tabletPadding;
    }

    @media(min-width: $desktopBkp){
      height: $desktopHeight;
      padding: $desktopPadding;
    }
  }
}

.ModelloLine{
  display: flex;
  align-items: center;
  gap: 4px;

  width: 100%;

  >.modelImage{
    $mobileHeight: 60px;
    $tabletHeight: 80px;
    $desktopHeight: 100px;

    aspect-ratio: 1 / 1;
    display: block;
    object-position: center;
    object-fit: cover;
    flex-shrink: 0;

    @media(min-width: $mobileBkp){
      height: $mobileHeight;
    }

    @media(min-width: $tabletBkp){
      height: $tabletHeight;
    }

    @media(min-width: $desktopBkp){
      height: $desktopHeight;
    }
  }

  >.deleteButton{
    $mobileHeight: 35px;
    $tabletHeight: 45px;
    $desktopHeight: 45px;
    $mobilePadding: 5px;
    $tabletPadding: 8px;
    $desktopPadding: 10px;

    background-color: var(--details3Color);

    aspect-ratio: 1 / 1;
    display: block;
    border-radius: 5px;
    flex-shrink: 0;

    margin-left: auto;
    cursor: pointer;

    @media(min-width: $mobileBkp){
      height: $mobileHeight;
      padding: $mobilePadding;
    }

    @media(min-width: $tabletBkp){
      height: $tabletHeight;
      padding: $tabletPadding;
    }

    @media(min-width: $desktopBkp){
      height: $desktopHeight;
      padding: $desktopPadding;
    }
  }
}
