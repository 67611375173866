@import "../variables.scss";

$desktopGap: 30px;
$tabletGap: 20px;
$mobileGap: 10px;

$desktopXSize: 40px;
$tabletXSize: 32px;
$mobileXSize: 24px;

$desktopWidth: 800px;
$tabletWidth: 600px;
$mobileWidth: 90%;

$transparenModeIncrementoMoltiplicatore: 1.2;

$desktopMaxHeight: 600px;
$tabletMaxHeight: 400px;
$mobileMaxHeight: 80%;

$desktopPadding: 24px;
$tabletPadding: 16px;
$mobilePadding: 8px;

.ModalLayout{
  position: fixed;

  top: 0;
  left: 0;
  overflow: hidden;

  background-color: var(--darkColorOpacity);
  backdrop-filter: blur(5px);
  animation-timing-function: linear;


  &.open{
    width: 100%;
    height: 100%;
  }

  z-index: 1000;

  >.modal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: white;

    box-shadow: 0 0 10px 0 var(--darkColorOpacity);
    overflow: hidden;

    outline: 1px solid var(--primaryColor);

    &.disableMaxHeight{
      max-height: unset !important;
    }

    &.open{
      animation-duration: 0.2s;
      animation-timing-function: linear;
      animation-name: openModalAnimation;
    }

    &.transparentMode{
      outline: 0;
      background-color: transparent;
      box-shadow: unset;

      @media(min-width: $mobileBkp){
        width: $mobileWidth;
        max-height: $mobileMaxHeight;
      }

      @media(min-width: $tabletBkp){
        width: $tabletWidth * $transparenModeIncrementoMoltiplicatore;
        max-height: $tabletMaxHeight * $transparenModeIncrementoMoltiplicatore;
      }

      @media(min-width: $desktopBkp){
        width: $desktopWidth * $transparenModeIncrementoMoltiplicatore;
        max-height: $desktopMaxHeight * $transparenModeIncrementoMoltiplicatore;
      }
    }

    @keyframes openModalAnimation {
      0%{
        transform: translateX(-50%) translateY(-50%) scaleX(0) scaleY(0) translateY(1000px);
      }

      100%{
        transform: translateX(-50%) translateY(-50%) scaleX(1) scaleY(1);
      }
    }

    @media(min-width: $mobileBkp){
      padding: $mobilePadding;
      width: $mobileWidth;
      height: auto;
      max-height: $mobileMaxHeight;
      gap: $mobileGap;
    }

    @media(min-width: $tabletBkp){
      padding: $tabletPadding;
      width: $tabletWidth;
      height: auto;
      max-height: $tabletMaxHeight;
      gap: $tabletGap;
    }

    @media(min-width: $desktopBkp){
      padding: $desktopPadding;
      width: $desktopWidth;
      height: auto;
      max-height: $desktopMaxHeight;
      gap: $desktopGap;
    }

    //Zona che visualizza la X sul pannello
    >.xZone{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      height: auto;
      flex-shrink: 0;
      padding-bottom: 4px;

      &.transparentMode{

      }

      //Icona X
      >.xIcon{
        aspect-ratio: 1 / 1;
        display: block;
        position: relative;

        cursor: pointer;

        @media(min-width: $mobileBkp){
          height: $mobileXSize;
        }

        @media(min-width: $tabletBkp){
          height: $tabletXSize;
        }

        @media(min-width: $desktopBkp){
          height: $desktopXSize;
        }

        &.transparentMode{
          transform: scaleX(1.3) scaleY(1.3);
        }

        &::before, &::after{
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;

          width: 5%;
          height: 60%;

          background-color: var(--primaryColor);
        }

        &::before{
          transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
        }

        &::after{
          transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
        }
      }
    }

    >.header, >.body, >.footer{
      display: block;
      width: 100%;
      flex-shrink: 0;
    }

    >.body{
      overflow-y: auto;
    }
  }
}
