@import "../../Layout/variables";

.Recap{
  $mobilePadding: 2px;
  $tabletPadding: 4px;
  $desktopPadding: 6px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow-x: hidden;

  width: 100%;
  background-color: var(--lightColor);

  border: solid 1px var(--details2Color);

  @media(min-width: $mobileBkp){
    padding: $mobilePadding;
  }

  @media(min-width: $tabletBkp){
    padding: $tabletPadding;
  }

  @media(min-width: $desktopBkp) {
    padding: $desktopPadding;
  }
}

.OrdineLineTopSeparator{
  $mobilePadding: 10px;
  $tabletPadding: 10px;
  $desktopPadding: 10px;

  $mobileGap: 5px;
  $tabletGap: 8px;
  $desktopGap: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  width: 100%;
  background-color: var(--lightColor);
  border: solid 1px var(--details2Color);

  position: relative;

  @media(min-width: $mobileBkp){
    padding: $mobilePadding;
    gap: $mobileGap;
  }

  @media(min-width: $tabletBkp){
    padding: $tabletPadding;
    gap: $tabletGap;
  }

  @media(min-width: $desktopBkp) {
    padding: $desktopPadding;
    gap: $desktopGap;
  }

  >.topSeparator{
    width: 100%;
    height: auto;
  }
}

.lineSeparator{
  width: 100%;
  height: auto;
}

.MiniaturaWrapper{
  display: flex;
  position: relative;

  >.TitleZone{
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    padding: 5px;

    background-color: var(--darkColor);
  }

  >.ButtonZone{
    display: flex;
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    bottom: 0;
    padding: 5px;
  }
}
