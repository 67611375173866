@import "../../Layout/variables";

.ContainerRenderizzatoreModelli{
  display: block;
  width: 100%;
  position: relative;

  >.ContainerComandiViste{
    display: flex;
    width: 100%;
    padding: 10px;
    height: auto;
    flex-direction: row;
    justify-content: space-between;

    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(100%);

    @media #{$mobileOnly} {
      padding: 0;
      position: relative;
      top: 0;
      transform: none;
    }

    >img{
      width: 40px;
      background-color: var(--details4Color);
      cursor: pointer;

      padding: 5px;
      border-radius: 4px;

      &:hover{
        background-color: var(--primaryColor);
      }
    }
  }

  >.ContainerVistaSelezionata{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;
    transform: translateY(-110%);

    >.ResponsiveLabel{
      @extend .DropShadow;
    }

    @media #{$mobileOnly} {
      width: calc(100% - 80px);

      top: -20px;
      transform: translateY(-50%);
      margin: 0 auto;

      >.ResponsiveLabel{
        color: var(--textColor);
        filter: unset;
      }
    }
  }
}
