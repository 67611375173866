@import "../variables";

.TwoSpaceLine{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 100%;
  gap: 5px;

  &.onlyMobileError{
    flex-direction: column;
    gap: 0;
    >.ResponsiveLabel{
      width: auto;
      margin-left: auto;
      text-align: right;
      background-color: var(--details3Color);
      color: var(--lightColor);
      padding: 2px;
    }
  }

  &.formMode{
    flex-direction: column-reverse;

    >.ResponsiveLabel{
      width: 100%;
    }

    >.TwoSpaceContainer{
      display: flex;
      flex-direction: column;
      align-items: stretch;

      >.ResponsiveLabel{
        text-align: left;
      }
    }
  }

  @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
    width: 100%;
    flex-direction: column-reverse;
    gap: 0;
    >.ResponsiveLabel{
      width: 100%;
      text-align: left;
      background-color: var(--details3Color);
      color: var(--lightColor);
      padding: 2px;
    }
  }

  >.TwoSpaceContainer{
    width: 100%;

    display: grid;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    grid-template-columns: 1fr 2fr;

    &.reduce{
      display: flex;
      flex-direction: row;
      width: auto;
      gap: 4px;
    }

    &:not(.noWrap){
      @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
        display: flex;
        flex-direction: column;
        align-items: stretch;

        gap: 5px;

        >.ResponsiveLabel{
          text-align: left;
        }
      }
    }

    >.Content{
      &.error{
        .DefaultInput{
          border-color: var(--details3Color);
        }
      }
    }
  }
}
