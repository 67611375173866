//Brakepoints da utilizzare nella realizzazione dei nuovi componenti
$mobileBkp: 0px;
$intermediateBkp: 400px;
$tabletBkp: 800px;
$desktopBkp: 1200px;

$mobileOnly: "only screen and (max-width: #{($tabletBkp)})";
$tabletOnly: "only screen and (min-width: #{$tabletBkp}) and (max-width: #{($desktopBkp)})";
$desktopOnly: "only screen and (min-width: #{$desktopBkp})";

@mixin desktop-only {
  @media #{$desktopOnly} {
    @content;
  }
}

@mixin tablet-only {
  @media #{$tabletOnly} {
    @content;
  }
}

@mixin mobile-only {
  @media #{$mobileOnly} {
    @content;
  }
}

//Dimensioni delle label da inserire a fianco di altri elementi
$mobileLabel: 18px;
$tabletLabel: 18px;
$desktopLabel: 18px;

//Gestione variabili default input
$mobileInputPadding: 5px;
$tabletInputPadding: 5px;
$desktopInputPadding: 8px;
$mobileInputFontSize: 15px;
$tabletInputFontSize: 15px;
$desktopInputFontSize: 15px;

//Gestione delle responsive col
$mobileResponsiveRowGap: 5px;
$tabletResponsiveRowGap: 8px;
$desktopResponsiveRowGap: 10px;
$mobileResponsiveColGap: 2px;
$tabletResponsiveColGap: 4px;
$desktopResponsiveColGap: 4px;

//Dimensioni delle sezioni
$mobileSectionGap: 5px;
$tabletSectionGap: 8px;
$desktopSectionGap: 10px;
$mobileSectionPadding: 20px;
$tabletSectionPadding: 20px;
$desktopSectionPadding: 16px;

.Shadow{
  position: relative;
  -webkit-box-shadow: 0 0 10px 0 rgba(50,50,50,0.8);
  box-shadow: 0 0 10px 0 rgba(50,50,50,0.8);
}

.DropShadow{
  filter:
          drop-shadow(2px 0px 0 rgba(50,50,50,0.8))
          drop-shadow(-2px 0px 0 rgba(50,50,50,0.8))
          drop-shadow(0px 2px 0 rgba(50,50,50,0.8))
          drop-shadow(0px -2px 0 rgba(50,50,50,0.8));
}

//ClassiGeneriche
.SizeManager{
  &.largeRelative{
    width: 100%;
  }

  &.mediumRelative{
    @media(min-width: $mobileBkp){
      width: 100%;
    }

    @media(min-width: $tabletBkp){
      width: 80%;
    }

    @media(min-width: $desktopBkp){
      width: 70%;
    }
  }

  &.smallRelative{
    @media(min-width: $mobileBkp){
      width: 100%;
    }

    @media(min-width: $tabletBkp){
      width: 70%;
    }

    @media(min-width: $desktopBkp){
      width: 50%;
    }
  }

  &.large{
    width: 100vw;
  }

  &.medium{
    @media(min-width: $mobileBkp){
      width: 100vw;
    }

    @media(min-width: $tabletBkp){
      width: 80vw;
    }

    @media(min-width: $desktopBkp){
      width: 70vw;
    }
  }

  &.small{
    @media(min-width: $mobileBkp){
      width: 100vw;
    }

    @media(min-width: $tabletBkp){
      width: 70vw;
    }

    @media(min-width: $desktopBkp){
      width: 50vw;
    }
  }
}
