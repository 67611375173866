@import "../variables";

.Responsive2Col{
  width: 100%;

  @media(min-width: $mobileBkp){
    display: flex;
    flex-direction: column;
    gap: $mobileResponsiveRowGap;
  }

  @media(min-width: $tabletBkp){
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $tabletResponsiveColGap;
    row-gap: $tabletResponsiveRowGap;
  }

  @media(min-width: $desktopBkp){
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $desktopResponsiveColGap;
    row-gap: $desktopResponsiveRowGap;
  }
}
