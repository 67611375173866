@import "../../Layout/variables";

.ProgressBar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 4px;

    >.ResponsiveLabel{
        width: auto;
    }

    >.ProgressBarLine{
        flex-grow: 1;
        background-color: var(--details4Color);
        height: 100%;
        align-items: flex-start;

        >span{
            display: flex;
            background-color: var(--primaryColor);
            height: 100%;

            >.ResponsiveLabel{
                color: var(--lightColor);
            }
        }
    }
}
