@import "../../../Layout/variables";

.RigaImpersonificazione{
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 10px;
  width: 99.7%;
  align-self: flex-start;
  background-color: var(--primaryColor);

  cursor: pointer;

  @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
    flex-direction: column;
  }

  >.ImmagineProfilo{
    aspect-ratio: 1 / 1;
    width: 80px;
    object-position: center;
    object-fit: cover;
  }

  >.ImpersonificazioneButton{
    aspect-ratio: 1 / 1;
    width: 40px;
    object-position: center;
    object-fit: cover;
  }
}
