@import "../../../../Layout/variables";

.ContainerCondiviso{
  width: 100%;
  flex-grow: 1;

  display: grid;
  grid-template-columns: 4fr 3fr;
  align-self: flex-start;
  align-items: center;

  &:has(.OverflowTag){
    overflow-y: auto;
  }

  gap: 10px;

  &.modalitaTest{
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  @media #{$mobileOnly}{
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  >.ContainerConfigurazione{
    height: 100%;

    &:has(.OverflowTag){
      overflow-y: auto;
    }
  }
}
