@import "../../Layout/variables";

.Button{
  background-color: var(--details2Color);
  cursor: pointer;

  //Tipi di button

  //Bottone come scritta
  &.simple{
    background-color: transparent;
    padding: 0;

    >.ResponsiveLabel{
      color: var(--primaryColor);
    }
  }

  //Bottone sottolineato
  &.simple-underline{
    @extend .simple;
    border-bottom: 2px solid var(--primaryColor);
  }

  //Bottone come scrittura classica sottolineato
  &.simple-text{
    @extend .simple-underline;
    border-bottom-color: var(--textColor);

    >.ResponsiveLabel{
      color: var(--textColor);
    }
  }

  &.simple-text-no-underline{
    @extend .simple-underline;
    border-bottom: unset;

    >.ResponsiveLabel{
      color: var(--textColor);
      text-align: left;
    }
  }

  &.simple-light{
    @extend .simple-underline;
    border-bottom-color: var(--lightColor);

    >.ResponsiveLabel{
      color: var(--lightColor);
    }
  }

  //Bottone senza proprietà
  &.basic{
    padding: 2px;
    height: auto;
  }

  //Bottone con l'icona della fotocamera per gli ordini
  &.ordine{
    $mobileWidth: 100%;
    $tabletWidth: 260px;
    $desktopWidth: 260px;
    $mobilePadding: 5px;
    $tabletPadding: 10px;
    $desktopPadding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;

    border: 1px solid var(--darkColor);
    border-radius: 10px;
    box-shadow: inset 0 0 20px 0 rgba(0,0,0,0.2);

    &:not(:disabled):hover{
      box-shadow: inset 0 0 50px 0 rgba(0,0,0,0.4);
    }

    @media(min-width: $mobileBkp){
      width: $mobileWidth;
      padding: $mobilePadding 0;
    }

    @media(min-width: $tabletBkp){
      width: $tabletWidth;
      padding: $tabletPadding 0;
    }

    @media(min-width: $desktopBkp){
      width: $desktopWidth;
      padding: $desktopPadding 0;
    }

    &::before{
      $desktopTabletSize: 50%;
      $mobileSize: 20%;

      content: "";
      aspect-ratio: 2 / 1;
      background-image: url("../../Media/Images/Icons/Svg/camera.svg");
      background-size: cover;
      background-position: center;

      width: 50%;
      @media(min-width: $mobileBkp){
        width: $mobileSize;
      }

      @media(min-width: $tabletBkp){
        width: $desktopTabletSize;
      }

      @media(min-width: $desktopBkp){
        width: $desktopTabletSize;
      }
    }
  }

  //Bottone del salva per dopo con l'icona di salvataggio
  &.salva-per-dopo{
    $mobileWidth: 100%;
    $tabletWidth: 260px;
    $desktopWidth: 260px;
    $mobilePadding: 5px;
    $tabletPadding: 10px;
    $desktopPadding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;

    border: 1px solid var(--darkColor);
    border-radius: 10px;
    box-shadow: inset 0 0 20px 0 rgba(0,0,0,0.2);

    &:not(:disabled):hover{
      box-shadow: inset 0 0 50px 0 rgba(0,0,0,0.4);
    }

    @media(min-width: $mobileBkp){
      width: $mobileWidth;
      padding: $mobilePadding 0;
    }

    @media(min-width: $tabletBkp){
      width: $tabletWidth;
      padding: $tabletPadding 0;
    }

    @media(min-width: $desktopBkp){
      width: $desktopWidth;
      padding: $desktopPadding 0;
    }

    &::before{
      $desktopTabletSize: 20%;
      $mobileSize: 10%;

      content: "";
      aspect-ratio: 1 / 1;
      background-image: url("../../Media/Images/Icons/storeIcon.png");
      background-size: cover;
      background-position: center;

      width: 50%;
      @media(min-width: $mobileBkp){
        width: $mobileSize;
      }

      @media(min-width: $tabletBkp){
        width: $desktopTabletSize;
      }

      @media(min-width: $desktopBkp){
        width: $desktopTabletSize;
      }
    }
  }

  //Bottone di submit classico
  &.submit{
    transition-duration: 0.05s;

    $mobileWidth: 100%;
    $tabletWidth: 260px;
    $desktopWidth: 260px;
    $mobilePadding: 10px;
    $tabletPadding: 15px;
    $desktopPadding: 15px;

    background-color: var(--primaryColor);
    border-radius: 10px;

    &:not(:disabled):hover{
      box-shadow: inset 0 0 50px 0 rgba(0,0,0,0.4);
    }

    @media(min-width: $mobileBkp){
      width: $mobileWidth;
      padding: $mobilePadding 0;
    }

    @media(min-width: $tabletBkp){
      width: $tabletWidth;
      padding: $tabletPadding 0;
    }

    @media(min-width: $desktopBkp){
      width: $desktopWidth;
      padding: $desktopPadding 0;
    }
  }

  //Bottone che va in full dello spazio ad esso dedicato
  &.full-normal, &.full-normal-negative, &.full-normal-not-rounded{
    $mobilePadding: 10px;
    $tabletPadding: 15px;
    $desktopPadding: 15px;

    border-radius: 10px;
    transition-duration: 0.05s;
    width: 100%;

    @media(min-width: $mobileBkp){
      padding: $mobilePadding 0;
    }

    @media(min-width: $tabletBkp){
      padding: $tabletPadding 0;
    }

    @media(min-width: $desktopBkp){
      padding: $desktopPadding 0;
    }
  }

  &.full-normal, &.full-normal-not-rounded{
    background-color: var(--primaryColor);

    &:not(:disabled):hover{
      box-shadow: inset 0 0 50px 0 rgba(0,0,0,0.4);
    }
  }

  &.full-normal-not-rounded{
    border-radius: 0;
  }

  &.full-normal-negative{
    background-color: var(--details3Color);

    &:not(:disabled):hover{
      box-shadow: inset 0 0 50px 0 rgba(0,0,0,0.4);
    }

    &:disabled{
      background: var(--details5Color);
    }
  }

  &.full-normal-login{
    $mobilePadding: 5px;
    $tabletPadding: 10px;
    $desktopPadding: 10px;

    @extend .full-normal;
    border-radius: 0;

    @media(min-width: $mobileBkp){
      padding: $mobilePadding 0;
    }

    @media(min-width: $tabletBkp){
      padding: $tabletPadding 0;
    }

    @media(min-width: $desktopBkp){
      padding: $desktopPadding 0;
    }
  }

  //Button a full con l'icona della foto a destra
  &.circle{
    $borderRadiusMobile: 20px;
    $borderRadiusTablet: 20px;
    $borderRadiusDesktop: 20px;
    $paddingMobile: 10px;
    $paddingTablet: 15px;
    $paddingDesktop: 15px;

    background-image: linear-gradient(180deg, rgba(255,255,255,0) 70%, rgba(0,0,0,0.2) 100%);
    width: 100%;

    &:not(:disabled):hover{
      background-image: linear-gradient(180deg, rgba(255,255,255,0) 30%, rgba(0,0,0,0.2) 100%);
    }

    position: relative;
    &::after{
      aspect-ratio: 1 / 1;

      content: "";
      display: block;

      position: absolute;

      height: 80%;
      top: 50%;
      transform: translateY(-50%);

      background-image: url("../../Media/Images/Icons/galleryIcon.png");
      background-size: cover;
      background-blend-mode: color;

      @media(min-width: $mobileBkp){
        right: $paddingMobile;
      }

      @media(min-width: $tabletBkp){
        right: $paddingTablet;
      }

      @media(min-width: $desktopBkp){
        right: $paddingDesktop;
      }
    }

    @media(min-width: $mobileBkp){
      border-radius: $borderRadiusMobile;
      padding: $paddingMobile;
    }

    @media(min-width: $tabletBkp){
      border-radius: $borderRadiusTablet;
      padding: $paddingTablet;
    }

    @media(min-width: $desktopBkp){
      border-radius: $borderRadiusDesktop;
      padding: $paddingDesktop;
    }
  }

  //Stati del button
  &:disabled{
    background-color: var(--details4Color);
    cursor: default;
  }

  &.left{
    margin-right: auto;
  }

  &.right{
    margin-left: auto;
  }

  &.center{
    margin-left: auto;
    margin-right: auto;
  }

  //Altre strutture interne
  >.ResponsiveLabel{
    color: var(--lightColor);
  }
}
