@import "../../Layout/variables";

.InputWrapper{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  width: 100%;
  position: relative;

  >span:not(.UploadZone){
    width: 100%;
  }

  >.UploadZone{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border: 2px dashed var(--primaryColor);
    cursor: pointer;
    background-color: var(--lightColor);

    .DefaultInput{
      border: 0;
      padding: 0;
      width: auto;
      text-align: center;
      cursor: pointer;
      color: var(--primaryColor);
    }

    &::after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .DefaultInput{
    width: 100%;
    border: 1px solid var(--details2Color);
    color: var(--textColor);

    &.noEdit{
      user-select: none;
      caret-color: transparent;
      cursor: default;
    }

    &.colorMode{
      -webkit-appearance: none;
      padding: 0;
      &::-webkit-color-swatch-wrapper{
        padding: 0;
      }
      &::-webkit-color-swatch{
        border: none;
      }

      @media(min-width: $mobileBkp){
        height: calc(#{$mobileInputFontSize} + #{$mobileInputPadding} + 12px);
      }

      @media(min-width: $tabletBkp){
        height: calc(#{$tabletInputFontSize} + #{$tabletInputPadding} + 12px);
      }

      @media(min-width: $desktopBkp){
        height: calc(#{$desktopInputFontSize} + #{$desktopInputPadding} + 12px);
      }

      cursor: pointer;
    }

    @media(min-width: $mobileBkp){
      font-size: $mobileInputFontSize;
      padding: $mobileInputPadding;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletInputFontSize;
      padding: $tabletInputPadding;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopInputFontSize;
      padding: $desktopInputPadding;
    }
  }

  .HideIcon{
    filter: invert(30%);
    width: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    cursor: pointer;

    @media(min-width: $mobileBkp){
      right: $mobileInputPadding;
    }

    @media(min-width: $tabletBkp){
      right: $tabletInputPadding;
    }

    @media(min-width: $desktopBkp){
      right: $desktopInputPadding;
    }
  }
}
