@import "../../Layout/variables";

.MultiSelettoreContainer{
  $gapDesktop: 10px;
  $gapTablet: 8px;
  $gapMobile: 5px;

  display: grid;
  width: 100%;
  align-items: center;

  @media(min-width: $mobileBkp){
    gap: $gapMobile;
    grid-template-columns: repeat(3, 1fr);
  }

  @media(min-width: $tabletBkp){
    gap: $gapTablet;
    grid-template-columns: repeat(4, 1fr);
  }

  @media(min-width: $desktopBkp){
    gap: $gapDesktop;
    grid-template-columns: repeat(5, 1fr);
  }

  >.SingoloSelettoreImmagineContainer{
    position: relative;
    width: 100%;
    height: min-content;
    overflow-y: hidden;
    cursor: pointer;

    &.multiploContenuto{
      --imageNumber: 2;

      display: grid;
      grid-template-columns: repeat(var(--imageNumber), 1fr);
      gap: inherit;
      grid-column: span var(--imageNumber);
      padding: 10px;
      border: 1px solid var(--details1Color);
    }

    >.SingoloSelettoreImmagine {
      width: 100%;
      display: block;
      background-position: center;
      background-size: cover;
      object-fit: contain;
      position: relative;
      flex-shrink: 0;

      &.escluso{
        cursor: default;
        pointer-events: none;
        border: 2px solid var(--details2Color);
      }

      &.selezionato{
        border: 2px solid var(--details3Color);
      }
    }
  }
}
