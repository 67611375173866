*{
  margin: 0;
  padding: 0;
  border: 0;

  box-sizing: border-box;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  outline: none;
  transition-duration: 0.2s;

  user-select: text;
  &:not(.testoSelezionabile){
    user-select: none;
  }
}

a{
  text-decoration: none;
}

html, body{
  height: 100%;
}

#root{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  height: 100%;
}

body{
  background-image: url("./Media/Images/Backgrounds/background0.jpg");
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

input[type=range]{
  appearance: none;
  height: 10px;
  background-color: var(--details4Color);
  border-radius: 2px;

  &::-webkit-slider-thumb{
    appearance: none;
    height: 13px;
    width: 10px;
    background-color: var(--details1Color);
    cursor: pointer;
    border-radius: 2px;
  }
}
