@import "../../Layout/variables";

.PaginationWrapper{
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 0;
  padding-bottom: 0;
  overflow-x: auto;
  flex-shrink: 0;

  >.PaginationButton{
    aspect-ratio: 3 / 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    flex-shrink: 0;

    border: 1px solid var(--details1Color);
    background-color: var(--details4Color);

    cursor: pointer;

    &:hover, &.selected{
      background-color: var(--details1Color);
    }
  }
}

.WrapperMultistato{
  height: 100%;

  @include mobile-only{
    height: auto;
  }
}
