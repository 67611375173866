.SearchLayout{
  display: grid;
  grid-template-columns: 5fr 2fr;
  gap: 5px;
  width: 100%;

  >.Button{
    background-color: var(--primaryColor);
  }
}
