@import "../../Layout/variables";

.NotificaContainerIcon{
  position: relative;

  >.NotificaIcon{
    aspect-ratio: 1 / 1;

    $mobileSize: 20px;
    $tabletSize: 23px;
    $desktopSize: 25px;

    @media(min-width: $mobileBkp){
      width: $mobileSize;
    }

    @media(min-width: $tabletBkp){
      width: $tabletSize;
    }

    @media(min-width: $desktopBkp){
      width: $desktopSize;
    }
  }

  >.counter{
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10px;
    color: var(--lightColor);
    background-color: var(--details3Color);
    border-radius: 50%;
    font-size: 6px;
    font-weight: bold;

    position: absolute;
    right: 0;
    top: 0;

    transform: translateX(50%);
  }
}
