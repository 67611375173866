@import "../../../Layout/variables";

$mobileSwitchSize: 40px;
$tabletSwitchSize: 50px;
$desktopSwitchSize: 50px;


.Switch{
  --currentColor: var(--details3Color);

  aspect-ratio: 2 / 1;
  border: 1px solid var(--currentColor);
  position: relative;
  padding: 2px;

  cursor: pointer;
  background-color: var(--lightColor);

  margin: 0 auto;
  border-radius: 25px;

  @include mobile-only {
    border-radius: 20px;
    width: $mobileSwitchSize;
  }

  @media(min-width: $tabletBkp){
    width: $tabletSwitchSize;
  }

  @media(min-width: $desktopBkp){
    width: $desktopSwitchSize;
  }

  &::after{
    content: "";
    aspect-ratio: 1 / 1;

    display: block;
    height: 100%;

    background-color: var(--currentColor);
    border-radius: 50%;

    position: relative;

    left: 0;
    top: 50%;
    transform: translateY(-50%);

    transition-duration: 0.2s;
  }

  &.disabled{
    --currentColor: var(--details4Color);
  }

  &.on{
    --currentColor: var(--primaryColor);
    &::after{
      --currentColor: var(--primaryColor);

      left: 100%;
      transform: translateY(-50%) translateX(-100%);
    }
  }
}
