@import "../../../Layout/variables";

.OnlyDesktopLayout{
  display: none;
  width: 100%;
  height: 100%;

  @media(min-width: $desktopBkp){
    display: block;
  }
}

.TabletMobileLayout{
  display: none;
  width: 100%;
  height: auto;

  @media(max-width: $desktopBkp){
    display: block;
  }
}

.ButtonTotem{
  @extend .Shadow;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 30px 0;
  width: 30%;
  font-size: 30px;
  border-radius: 10px;

  color: var(--lightColor);
  background-color: var(--primaryColor);
  height: auto;

  cursor: pointer;

  opacity: 0.8;
  &:hover{
    opacity: 1;
  }
}

.ListItems{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: scroll;
  gap: 10px;

  &::-webkit-scrollbar{
    display: none;
  }

  >.Item{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 100%;

    background-color: white;
    background-position-x: center;
    background-position-y: center;
    padding: 10px;
    border: var(--totemTextColor) solid 2px;
    gap: 5px;

    cursor: pointer;

    .ResponsiveLabel{
      color: var(--totemTextColor);
    }

    &.selected{
      background-color: var(--totemTextColor);
      .ResponsiveLabel{
        color: var(--lightColor);
      }
    }

    >.ItemImage{
      aspect-ratio: 3 / 2;

      flex-shrink: 0;
      width: 100%;
      background-size: cover;
      border-radius: 2px;

      background-size: cover;
      background-position: center;
      background-color: var(--lightColor);
    }
  }
}
