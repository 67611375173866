.PrivacylayoutContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid var(--primaryColor);
  background-color: var(--lightColor);
  padding: 16px;
  gap: 8px;
  margin: 16px 0;
}
