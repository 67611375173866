@import "../variables.scss";

.VerticalSpace{
  --gapMobile: 0;
  --gapTablet: 0;
  --gapDesktop: 0;

  @media(min-width: $mobileBkp){
    padding-top: var(--gapMobile);
  }

  @media(min-width: $tabletBkp){
    padding-top: var(--gapTablet);
  }

  @media(min-width: $desktopBkp){
    padding-top: var(--gapDesktop);
  }
}
