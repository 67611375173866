.CanvasContainer{
  display: block;
  width: auto;
  height: auto;

  position: relative;

  >.ComandiContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    position: absolute;
    left: 10px;
    top: 10px;
    width: calc(100% - 20px);
    height: auto;

    >.ZonaCamera{
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: auto;
      gap: 2px;
    }

    >.ZonaTrasformazioni{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: auto;
      gap: 2px;
    }

    .ComandoButton{
      display: flex;
      flex-direction: row;
      gap: 5px;
      padding: 3px;
      border-radius: 5px;
      align-items: center;
      background-color: var(--details1Color);
      cursor: pointer;

      >.ComandoButtonImage{
        aspect-ratio: 1 / 1;
        width: 20px;
        object-fit: cover;
      }

      &:hover, &.selected{
        background-color: var(--primaryColor);
      }
    }
  }
}
