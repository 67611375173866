@import "../../Layout/variables";

.SubmenuContainer{
  $paddingDesktop: 0 15%;
  $paddingTablet: 0 10%;
  $paddingMobile: 0 15px;

  display: flex;
  flex-direction: column;
  background-color: var(--primaryColor);

  width: 100%;
  height: auto;

  @media(min-width: $mobileBkp){
    padding: $paddingMobile;
  }

  @media(min-width: $tabletBkp){
    padding: $paddingTablet;
  }

  @media(min-width: $desktopBkp){
    padding: $paddingDesktop;
  }

  >.AzioniRapideContainer{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: flex-start;

    >.AzioneRapida{
      $heightMobile: 30px;
      $heightTablet: 50px;
      $heightDesktop: 50px;

      aspect-ratio: 1 / 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2px;
      cursor: pointer;

      border-right: 2px solid var(--lightColor);

      &:last-child{
        border-left: 2px solid var(--lightColor);
      }

      @media(min-width: $mobileBkp){
        height: $heightMobile;
      }

      @media(min-width: $tabletBkp){
        height: $heightTablet;
      }

      @media(min-width: $desktopBkp){
        height: $heightDesktop;
      }

      >.AzioneRapidaIcon{
        $sizeDesktop: 25px;
        $sizeTablet: 23px;
        $sizeMobile: 20px;

        flex-shrink: 0;

        @media(min-width: $mobileBkp){
          width: $sizeMobile;
        }

        @media(min-width: $tabletBkp){
          width: $sizeTablet;
        }

        @media(min-width: $desktopBkp){
          width: $sizeDesktop;
        }
      }

      >.ResponsiveLabel{
        width: auto;
        @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
          display: none;
        }
      }
    }
  }

  box-shadow: 0 2px 2px 0 rgba(50, 50, 50, 0.8);
}

.SubmenuItemsContainer{
  $paddingDesktop: 5px 15%;
  $paddingTablet: 5px 10%;
  $paddingMobile: 5px 30px;

  width: 100%;
  transition-duration: 0s;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  position: sticky;
  top: 0;

  box-shadow: 0 2px 2px 0 rgba(50, 50, 50, 0.8);

  background-color: var(--primaryColor);
  z-index: 100;

  @media(min-width: $mobileBkp){
    padding: $paddingMobile;
  }

  @media(min-width: $tabletBkp){
    padding: $paddingTablet;
  }

  @media(min-width: $desktopBkp){
    padding: $paddingDesktop;
  }

  &:has(.CategorySwitcherElement){
    >.SubmenuItem {
      &:not(.sempreVisibile){
        display: none;
      }
      &:is(.sempreVisibile) {
        margin-right: 40px;
      }
    }

    >.SubmenuItem:is(.visibileSoloInCollaborazione){
      display: flex;
    }
  }

  >.SubmenuItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding-bottom: 2px;
    gap: 2px;
    border-color: var(--lightColor);
    position: relative;

    &.visibileSoloInCollaborazione{
      display: none;
      margin-right: 30px;
    }

    &:hover, &.selected{
      border-bottom: 2px solid var(--lightColor);
    }

    >.SubmenuItemIcon{
      $sizeDesktop: 30px;
      $sizeTablet: 25px;
      $sizeMobile: 25px;

      flex-shrink: 0;

      @media(min-width: $mobileBkp){
        width: $sizeMobile;
      }

      @media(min-width: $tabletBkp){
        width: $sizeTablet;
      }

      @media(min-width: $desktopBkp){
        width: $sizeDesktop;
      }
    }

    >.ResponsiveLabel{
      width: auto;
      @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
        display: none;
      }
    }

    @media(min-width: $tabletBkp){
      &:hover{
        >.SubitemContainer{
          display: flex;
        }
      }
    }

    >.SubitemContainer{
      display: none;
      flex-direction: column;
      position: absolute;
      top: calc(100%);
      left: 0;
      padding-top: 10px;

      >.Subitem{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        padding: 4px;
        padding-right: 9px;
        background-color: var(--primaryColor);

        @extend .Shadow;

        &:hover{
          padding-left: 9px;
          padding-right: 4px;
        }

        >.SubitemIcon{
          aspect-ratio: 1 / 1;
          flex-shrink: 0;
          width: 15px;
        }

        >.ResponsiveLabel{
          min-width: max-content;
        }
      }
    }
  }
}
