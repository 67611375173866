@import "../../Layout/variables";

.ImagePreviewOverlay{
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--darkColorOpacity);
  backdrop-filter: blur(10px);

  >.imageContainer{
    $desktopWidth: 800px;
    $tabletWidth: 600px;
    $mobileWidth: 100%;

    display: block;
    width: 100%;
    height: auto;
    max-height: 80vh;
    overflow-y: hidden;

    @media(min-width: $mobileBkp){
      max-width: $mobileWidth;
      padding: 30px;
    }

    @media(min-width: $tabletBkp){
      max-width: $tabletWidth;
      padding: 0;
    }

    @media(min-width: $desktopBkp){
      max-width: $desktopWidth;
      padding: 0;
    }

    >.xIconLine{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: var(--primaryColor);
      padding: 10px;
      gap: 10px;

      >.closeIcon{
        width: 20px;
        cursor: pointer;
      }
    }

    >.fullImage{
      width: 100%;
    }
  }
}
