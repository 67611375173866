@import "../../Layout/variables";

.DADMHeaderUploader{
  $paddingDesktop: 30px;
  $paddingTablet: 30px;
  $paddingMobile: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 10px;

  border: 2px dashed var(--details2Color);
  background-color: var(--lightColor);

  cursor: pointer;

  @media(min-width: $mobileBkp){
    padding: $paddingMobile;
  }

  @media(min-width: $tabletBkp){
    padding: $paddingTablet;
  }

  @media(min-width: $desktopBkp){
    padding: $paddingDesktop;
  }

  &.upload{
    flex-direction: column;
  }

  >.ResponsiveLabel{
    width: auto;
  }

  >.UploadIcon{
    display: block;
    width: 40px;
    padding: 5px;
    background-color: var(--details2Color);
    border-radius: 10px;
  }
}

.DADMOverlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  background-color: var(--darkColorOpacity);

  >.OverlayRow{
    $mobileFontSize: 10px;
    $tabletFontSize: 13px;
    $desktopFontSize: 13px;

    color: var(--lightColor);
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin: 5px;
    z-index: 100;

    @media(min-width: $mobileBkp){
      font-size: $mobileFontSize;
    }

    @media(min-width: $tabletBkp){
      font-size: $tabletFontSize;
    }

    @media(min-width: $desktopBkp){
      font-size: $desktopFontSize;
    }

    >.DeleteButton{
      aspect-ratio: 1 / 1;

      content: "x";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 5px;
      height: 100%;
      background-color: var(--details3Color);
      border-radius: 2px;

      background-image: url("../../Media/Images/Icons/xIcon.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 70%;

      cursor: pointer;
    }
  }
}
