@import "../../../Layout/variables";

.TotemContainer{
  --totemTextColor: #013145;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-size: 100%;
  background-position: top center;
  position: relative;

  >.MenuContainer{
    display: grid;
    width: 100%;
    height: 100%;

    grid-template-rows: 1fr 2fr 3fr 1fr;
    align-items: center;
    padding-bottom: 50px;

    >.ButtonContainer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      height: auto;

      gap: 10%;
    }

    >.ResponsiveLabel{
      color: var(--totemTextColor);
    }

    >.Logo{
      @extend .Shadow;
      display: block;
      width: 20%;
    }
  }

  >.ConfiguratoreContainer{
    display: grid;
    width: 100%;
    height: 100%;
    max-height: 100%;

    padding: 10px;

    grid-template-columns: 2fr 6fr 2fr;
    align-items: stretch;
    position: relative;

    overflow-y: hidden;

    .Section .ResponsiveLabel{
      color: var(--totemTextColor);
      font-size: 30px;
      margin: 0;
    }

    .Section .Loader >.ball{
      &::before{
        background-color: var(--totemTextColor);
      }
    }

    >.EditorZone{
      $tipZoneHeight: 30px;

      display: flex;
      flex-direction: column;
      align-items: stretch;

      width: 50%;
      height: auto;
      position: absolute;
      left: 50%;
      top: calc(100% - #{$tipZoneHeight});
      transform: translateX(-50%);

      background-color: rgba(200, 200, 200, 0.9);
      border: var(--totemTextColor) 1px solid;

      &.open{
        top: 100%;
        transform: translateX(-50%) translateY(calc(-100% - 20px));
        &::before{
          opacity: 0.8;
          transform: rotateX(0);
        }
      }

      &::before{
        content: "";
        display: block;
        background-color: red;
        width: 100%;
        height: $tipZoneHeight;
        background-color: var(--totemTextColor);

        background-image: url("../../../Media/Images/Icons/downArrowIcon2.png");
        background-size: 30px 60%;
        background-repeat: no-repeat;
        background-position: center;

        transform: rotateX(180deg);

        cursor: pointer;
      }

      >.ContentZone{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
        padding: 20px;

        .ResponsiveLabel{
          color: var(--totemTextColor);
        }

        .TwoSpaceLine{
          >.ResponsiveLabel{
            display: none;
          }

          .DefaultInput{
            border-color: var(--totemTextColor);
          }
        }
      }
    }

    >.ButtonZone{
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 30px;

      >.Logo{
        @extend .Shadow;
        width: 100%;
      }

      >.ButtonTotem{
        width: 100%;
      }
    }
  }
}
