@import "../../Layout/variables";

.ChatLine{
  $mobilePadding: 15px;
  $tabletPadding: 20px 15px;
  $desktopPadding: 20px 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  background-color: var(--primaryColor);
  gap: 10px;

  @media(min-width: $mobileBkp){
    padding: $mobilePadding;
  }

  @media(min-width: $tabletBkp){
    padding: $tabletPadding;
  }

  @media(min-width: $desktopBkp){
    padding: $desktopPadding;
  }

  cursor: pointer;

  >.InfoZone{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 5px;
  }
}
