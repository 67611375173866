@import "../../../../Layout/variables";

.ChatOverlayMessage{
  $desktopPadding: 10px;
  $tabletPadding: 8px;
  $mobilePadding: 5px;
  $desktopGap: 15px;
  $tabletGap: 10px;
  $mobileGap: 5px;

  display: flex;
  flex-direction: column;

  border: 3px solid var(--primaryColor);

  border-radius: 10px;

  @media(min-width: $mobileBkp){
    padding: $mobilePadding;
    gap: $mobileGap;
    min-width: 100%;
    max-width: 100%;
  }

  @media(min-width: $tabletBkp){
    padding: $tabletPadding;
    gap: $tabletGap;
    min-width: 60%;
    max-width: 80%;
  }

  @media(min-width: $desktopBkp){
    padding: $desktopPadding;
    gap: $desktopGap;
    min-width: 60%;
    max-width: 80%;
  }

  &.owner{
    margin-left: auto;
    border-bottom-left-radius: 0;
  }

  &:not(.owner){
    border-bottom-right-radius: 0;
  }

  >.title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
      flex-direction: column;
      gap: 4px;
      >.ResponsiveLabel{
        text-align: left;
        word-break: break-all;
      }
    }
  }
}
