@import "../../../Layout/variables.scss";

.CategorySwitcherContainer{
    .CategorySwitcherElement{
        $desktopGap: 8px;

        $desktopPadding: 10px;
        $tabletPadding: 5px 20px;
        $mobilePadding: 10px;

        $desktopIconSize: 30px;
        $tabletIconSize: 50px;
        $mobileIconSize: 40px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        cursor: pointer;
        flex-shrink: 0;

        &.grow{
            flex-grow: 1;
        }

        &.selected{
            outline: 1px solid var(--lightColor);
            background-color: var(--details2Color);
        }

        @media(min-width: $mobileBkp){
            padding: $mobilePadding;
            gap: 0;
        }

        @media(min-width: $tabletBkp){
            padding: $tabletPadding;
            gap: 0;
        }

        @media(min-width: $desktopBkp){
            padding: $desktopPadding;
            gap: $desktopGap;
        }

        >.icon{
            aspect-ratio: 1 / 1;

            @media(min-width: $mobileBkp){
                width: $mobileIconSize;
            }

            @media(min-width: $tabletBkp){
                width: $tabletIconSize;
            }

            @media(min-width: $desktopBkp){
                width: $desktopIconSize;
            }
        }

        >.CategoryLabel{
            width: 100%;

            @media(min-width: $mobileBkp){
                display: none;
            }

            @media(min-width: $tabletBkp){
                display: none;
            }

            @media(min-width: $desktopBkp){
                display: inline-block;
            }
        }
    }
}

.SubmenuItemsContainer{
    >.CategorySwitcherElement{
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        padding-bottom: 2px;
        gap: 2px;
        border-color: var(--lightColor);

        &:hover, &.selected{
            border-bottom: 2px solid var(--lightColor);
        }

        >.icon{
            $sizeDesktop: 30px;
            $sizeTablet: 25px;
            $sizeMobile: 25px;

            flex-shrink: 0;

            @media(min-width: $mobileBkp){
                width: $sizeMobile;
            }

            @media(min-width: $tabletBkp){
                width: $sizeTablet;
            }

            @media(min-width: $desktopBkp){
                width: $sizeDesktop;
            }
        }

        >.CategoryLabel{
            width: auto;
            @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
                display: none;
            }
            text-transform: uppercase;
        }
    }
}
