@import "../../Layout/variables";

$paddingDesktop: 10px;
$paddingTablet: 10px;
$paddingMobile: 10px;

$dataSizeDesktop: 20px;
$dataSizeTablet: 18px;
$dataSizeMobile: 15px;

$cellElementsGapDesktop: 5px;
$cellElementsGapTablet: 5px;
$cellElementsGapMobile: 8px;

.DatePickerContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 5px;
    background-color: var(--details1Color);

    width: min-content;
    height: min-content;

    @media(min-width: $desktopBkp){
        position: sticky;
        top: 0px; //Dimensione in desktop del submenu e del padding superiore
    }

    @media(min-width: $mobileBkp) and (max-width: $desktopBkp){
        cursor: pointer;
    }

    @media(min-width: $mobileBkp) and (max-width: $intermediateBkp){
        &.fullWidthMobile{
            width: 100%;
        }
    }

    &.center{
        margin: 0 auto;
    }

    >.RecapContainer{
        display: flex;
        flex-direction: row;
        background-color: var(--primaryColor);

        @media(min-width: $mobileBkp){
            align-items: center;
            padding: $paddingMobile;
        }

        @media(min-width: $tabletBkp){
            align-items: flex-end;
            padding: $paddingTablet;
        }

        @media(min-width: $desktopBkp){
            align-items: flex-end;
            padding: $paddingDesktop;
        }

        >.dataContainer{
            display: grid;
            width: 100%;
            color: var(--lightColor);
            align-items: flex-end;
            justify-content: center;
            column-gap: 5px;

            @media(min-width: $mobileBkp){
                grid-template: "A C B" auto / auto auto auto;
            }

            @media(min-width: $tabletBkp){
                text-transform: uppercase;
                grid-template:
                        "A B" auto
                        "C C" auto / auto auto;
            }

            @media(min-width: $desktopBkp){
                grid-template:
                        "A B" auto
                        "C C" auto / auto auto;
            }

            >*:nth-child(1){
                grid-area: A;
                text-align: right;
                color: var(--details4Color);
                @media(min-width: $mobileBkp){
                    font-size: $dataSizeMobile;
                }

                @media(min-width: $tabletBkp){
                    font-size: $dataSizeTablet * 1.2;
                }

                @media(min-width: $desktopBkp){
                    font-size: $dataSizeDesktop * 1.2;
                }
            }
            >*:nth-child(2){
                grid-area: B;
                @media(min-width: $mobileBkp){
                    font-size: $dataSizeMobile;
                }

                @media(min-width: $tabletBkp){
                    font-size: $dataSizeTablet;
                }

                @media(min-width: $desktopBkp){
                    font-size: $dataSizeDesktop;
                }
            }
            >*:nth-child(3){
                grid-area: C;
                @media(min-width: $mobileBkp){
                    font-size: $dataSizeMobile;
                }

                @media(min-width: $tabletBkp){
                    font-size: $dataSizeTablet * 1.5;
                }

                @media(min-width: $desktopBkp){
                    font-size: $dataSizeDesktop * 1.5;
                }
            }
        }

        >.leftArrow, >.rightArrow{
            &::after{
                content: "<";
                color: white;
                @media(min-width: $mobileBkp){
                    font-size: $dataSizeMobile;
                }

                @media(min-width: $tabletBkp){
                    font-size: $dataSizeTablet * 1.5;
                }

                @media(min-width: $desktopBkp){
                    font-size: $dataSizeDesktop * 1.5;
                }

                cursor: pointer;
            }
        }

        >.rightArrow::after{
            content: ">";
        }
    }

    &:not(:hover){
        >.CalendarContainer{
            @media(min-width: $mobileBkp) and (max-width: $desktopBkp){
                overflow: hidden;
                padding-top: 0;
                padding-bottom: 0;
                max-height: 0;
            }
        }
    }

    >.CalendarContainer{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        height: auto;
        max-height: 2000px;

        @media(min-width: $mobileBkp){
            gap: $cellElementsGapMobile;
            padding: $paddingMobile;
        }

        @media(min-width: $tabletBkp){
            gap: $cellElementsGapTablet;
            padding: $paddingTablet;
        }

        @media(min-width: $desktopBkp){
            gap: $cellElementsGapDesktop;
            padding: $paddingDesktop;
        }

        .CellElement{
            aspect-ratio: 1 / 1;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--lightColor);
            height: auto;


            @media(min-width: $mobileBkp){
                min-width: $dataSizeMobile * 2;
            }

            @media(min-width: $tabletBkp){
                min-width: $dataSizeTablet * 2;
            }

            @media(min-width: $desktopBkp){
                min-width: $dataSizeDesktop * 2;
            }
        }

        >.dayLabelContainer{
            display: grid;
            grid-template-columns: repeat(7, 1fr);

            @media(min-width: $mobileBkp){
                column-gap: $cellElementsGapMobile;
                row-gap: $cellElementsGapMobile;
            }

            @media(min-width: $tabletBkp){
                column-gap: $cellElementsGapTablet;
                row-gap: $cellElementsGapTablet;
            }

            @media(min-width: $desktopBkp){
                column-gap: $cellElementsGapDesktop;
                row-gap: $cellElementsGapDesktop;
            }


            >*{
                @extend .CellElement;
                @media(min-width: $mobileBkp){
                    font-size: $dataSizeMobile * 1.2;
                }

                @media(min-width: $tabletBkp){
                    font-size: $dataSizeTablet * 1.2;
                }

                @media(min-width: $desktopBkp){
                    font-size: $dataSizeDesktop * 1.2;
                }
            }
        }

        >.daysContainer{
            display: grid;
            grid-template-columns: repeat(7, 1fr);

            @media(min-width: $mobileBkp){
                column-gap: $cellElementsGapMobile;
                row-gap: $cellElementsGapMobile;
            }

            @media(min-width: $tabletBkp){
                column-gap: $cellElementsGapTablet;
                row-gap: $cellElementsGapTablet;
            }

            @media(min-width: $desktopBkp){
                column-gap: $cellElementsGapDesktop;
                row-gap: $cellElementsGapDesktop;
            }

            >*{
                @extend .CellElement;
                &:not(.empty){
                    cursor: pointer;
                    background-color: var(--lightColor);
                    color: var(--primaryColor);
                    @media(min-width: $mobileBkp){
                        font-size: $dataSizeMobile;
                    }

                    @media(min-width: $tabletBkp){
                        font-size: $dataSizeTablet;
                    }

                    @media(min-width: $desktopBkp){
                        font-size: $dataSizeDesktop;
                    }

                    &:hover, &.selected{
                        background-color: var(--details4Color);
                        color: var(--lightColor);
                    }

                    &.green{
                        color: forestgreen;
                        text-decoration: underline;
                    }

                    &.red{
                        color: red;
                    }
                }
            }
        }
    }
}

