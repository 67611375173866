.ChatContainerIcon{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    >.ChatIcon{
        width: 28px;
    }

    ._counterStyle{
        aspect-ratio: 1 / 1;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 20px;
        color: var(--lightColor);
        border-radius: 50%;
        font-size: 10px;
        font-weight: bold;
    }

    >.counter{
        @extend ._counterStyle;
        background-color: var(--details3Color);
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%) translateY(-30%);

        &.standAloneColor{
            color: var(--darkColor);
            background-color: gold;
        }

        >.sottoCounter{
            @extend ._counterStyle;
            font-size: 8px;
            min-width: 15px;
            color: var(--darkColor);
            background-color: gold;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(100%) translateY(-30%);
        }
    }
}
