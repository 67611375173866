@import "../../Layout/variables";

.OrdineLineContainer{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  align-self: flex-start;
  height: auto;
  background-color: var(--primaryColor);

  >.Header{
    $paddingDesktop: 12px;
    $paddingTablet: 12px;
    $paddingMobile: 8px;

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    background-color: var(--primaryColor);
    border-radius: 10px;

    cursor: pointer;

    @media(min-width: $mobileBkp){
      padding: $paddingMobile;
    }

    @media(min-width: $tabletBkp){
      padding: $paddingTablet;
    }

    @media(min-width: $desktopBkp){
      padding: $paddingDesktop;
    }

    >.BasicInfoLine{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      align-items: center;

      >.InfoOrdineZone{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        flex-wrap: wrap;

        >.ResponsiveLabel{
          flex-shrink: 0;
          width: auto;
          margin: 0;
        }
      }

      >.IconZone{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        >.icon{
          width: 26px;
        }

        >.iconLarge{
          width: 28px;
        }
      }
    }

    >.InfoLine{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      overflow-x: hidden;
      flex-wrap: wrap;
      gap: 4px;

      >.ResponsiveLabel{
        flex-shrink: 0;
        width: auto;
        margin: 0;
      }
    }
  }

  >.Body{
    $paddingDesktop: 10px;
    $paddingTablet: 10px;
    $paddingMobile: 10px 8px;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;

    background-color: var(--details4Color);
    margin: 5px;

    height: auto;

    @media(min-width: $mobileBkp){
      padding: $paddingMobile;
    }

    @media(min-width: $tabletBkp){
      padding: $paddingTablet;
    }

    @media(min-width: $desktopBkp){
      padding: $paddingDesktop;
    }
  }
}
