@import "../variables";

.CategorySectionLayout{
  $desktopMarginTop: 20px;
  $tabletMarginTop: 20px;
  $mobileMarginTop: 20px;

  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  border: 1px solid var(--details2Color);
  background-color: var(--lightColor);

  @include mobile-only{
    padding: $mobileSectionPadding;
    width: 100%;
    gap: $mobileSectionGap;
    margin-top: $mobileMarginTop;
  }

  @media(min-width: $tabletBkp){
    padding: $tabletSectionPadding 10%;
    gap: $tabletSectionGap;
    margin-top: $tabletMarginTop;
  }

  @media(min-width: $desktopBkp){
    padding: $desktopSectionPadding 10%;
    gap: $desktopSectionGap;
    margin-top: $desktopMarginTop;
  }

  >.CategoryHeader{
    display: flex;
    flex-direction: row;
    align-items: center;

    position: absolute;
    padding: 0 2px;
    margin-left: 20px;
    top: 0;
    left: 0;
    transform: translateY(-50%);
    width: auto;
    max-width: calc(100% - 40px);
    height: 2px;

    >.ResponsiveLabel{
      color: var(--details2Color);
      margin-top: -8px;
    }

    @include mobile-only {
      margin-left: 50%;
      transform: translateX(-50%) translateY(-60%);
    }

    &::before{
      content: "";
      display: block;
      width: 100%;
      background-color: var(--lightColor);
      height: 4px;

      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-50%);
      z-index: -10;
    }
  }
}
