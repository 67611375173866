@import "../../Layout/variables.scss";

$SelettoreSizeMobile: 200px;
$SelettoreSizeTablet: 100%;
$SelettoreSizeDesktop: 100%;

$SelettoreGapMobile: 3px;
$SelettoreGapTablet: 5px;
$SelettoreGapDesktop: 5px;

$SelettorePaddingMobile: 5px;
$SelettorePaddingTablet: 10px;
$SelettorePaddingDesktop: 20px;

.SelettoreQuadrato{
  aspect-ratio: 1 / 1;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;

  margin: 0 auto;

  @media(min-width: $mobileBkp){
    width: $SelettoreSizeMobile;
    gap: $SelettoreGapMobile;
    padding: $SelettorePaddingMobile;
  }

  @media(min-width: $tabletBkp){
    width: $SelettoreSizeTablet;
    gap: $SelettoreGapTablet;
    padding: $SelettorePaddingTablet;
  }

  @media(min-width: $desktopBkp){
    width: $SelettoreSizeDesktop;
    gap: $SelettoreGapDesktop;
    padding: $SelettorePaddingDesktop;
  }

  .cell{
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--details2Color);

    cursor: pointer;

    background-color: white;

    &:hover{
      background-color: var(--details4Color);
    }

    >img{
      display: block;
      width: 80%;
      height: 80%;

      filter: invert(100%);
    }
  }
}
