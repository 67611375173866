.SentenceLayout{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;

  width: auto;
  height: auto;

  >*{
    width: auto;
  }

  &.largeGap{
    gap: 20px;
    row-gap: 5px;
  }

  &.fullWidth{
    width: 100%;
  }

  &.left{
    margin-right: auto;
  }

  &.center{
    margin-right: auto;
    margin-left: auto;
  }

  &.right{
    margin-left: auto;
  }
}
