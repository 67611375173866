@import "../../../../Layout/variables";

.ChatOverlayHead{
  $desktopPadding: 10px 5px;
  $tabletPadding: 10px 5px;
  $mobilePadding: 10px 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: var(--primaryColor);

  @media(min-width: $mobileBkp){
    padding: $mobilePadding;
  }

  @media(min-width: $tabletBkp){
    padding: $tabletPadding;
  }

  @media(min-width: $desktopBkp){
    padding: $desktopPadding;
  }

  >.buttonZone{
    $desktopGap: 5px;
    $tabletGap: 5px;
    $mobileGap: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;

    @media(min-width: $mobileBkp){
      gap: $mobileGap;
    }

    @media(min-width: $tabletBkp){
      gap: $tabletGap;
    }

    @media(min-width: $desktopBkp){
      gap: $desktopGap;
    }

    >.button{
      $desktopSize: 30px;
      $tabletSize: 25px;
      $mobileSize: 20px;

      cursor: pointer;

      @media(min-width: $mobileBkp){
        width: $mobileSize;
      }

      @media(min-width: $tabletBkp){
        width: $tabletSize;
      }

      @media(min-width: $desktopBkp){
        width: $desktopSize;
      }
    }
  }
}
