.PreviewAlberaturaModelloContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;

  ul{
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
  }

  li{
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;

    &.draggable{
      outline: 1px dotted var(--textColor);
    }

    &::before{
      content: '-';
    }

    &.root{
      outline: 1px dashed var(--textColor);
      &::before{
        content: '' !important;
      }
    }

    &:has(>ul){
      &::before{
        content: '   ╚';
      }
    }

    &:first-child{
      &::before{
        content: '═╦═';
      }
    }

    &::before{
      content: '   ╠═';
    }
  }
}
