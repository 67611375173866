@import "../../../Layout/variables";

.CheckboxButton{
  display: flex;
  flex-direction: column;
  align-items: center;

  width: auto;
  gap: 5px;

  cursor: pointer;

  &:hover, &.checked{
    >.ResponsiveLabel{
      text-decoration: underline;
    }
  }

  >.ResponsiveLabel{
    text-decoration: unset;
  }
}
