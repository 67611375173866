@import "../../Layout/variables";

.HeaderContainer{
  $paddingDesktop: 8px 15%;
  $paddingTablet: 8px 10%;
  $paddingMobile: 5px 15px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: var(--primaryColor);

  width: 100%;
  height: auto;

  @media(min-width: $mobileBkp){
    padding: $paddingMobile;
  }

  @media(min-width: $tabletBkp){
    padding: $paddingTablet;
  }

  @media(min-width: $desktopBkp){
    padding: $paddingDesktop;
  }

  >.LogoBase{
    aspect-ratio: unset;
    margin-right: auto;
    justify-content: flex-start;
    margin-left: -2%;
  }

  >.HeaderUserInfoContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    gap: 10px;
    cursor: pointer;

    >.HeaderUserInfoContainerLeftSide{
      display: flex;
      flex-direction: column;

      @media(min-width: $mobileBkp) and (max-width: $tabletBkp){
        display: none;
      }
    }

    >.HeaderUserInfoContainerRightSide{
      $sizeDesktop: 60px;
      $sizeTablet: 50px;
      $sizeMobile: 40px;

      aspect-ratio: 1 / 1;
      border-radius: 50%;

      background-position: center;
      background-size: cover;
      background-color: black;

      flex-shrink: 0;

      @media(min-width: $mobileBkp){
        width: $sizeMobile;
      }

      @media(min-width: $tabletBkp){
        width: $sizeTablet;
      }

      @media(min-width: $desktopBkp){
        width: $sizeDesktop;
      }
    }
  }
}
